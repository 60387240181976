import React from "react";
import { useSelector } from "react-redux";
import { setCurrentContractId } from 'store/slices/contractSlice';
import { setBladeMode } from 'store/slices/systemSlice';
import { Contract } from "store/models/Contract";
import usePermissions, { ActionType, FeatureType, UIActionType } from "hooks/usePermissions";
import { getUserFullName } from "utils/util";
import { Typography, ListItemButton, ListItemText, ListItemSecondaryAction } from "@mui/material";
import { selectUsers } from "store/slices/userSlice";
import { useAppDispatch } from 'store/store';

interface Props {
  contract: Contract;
}
const ClientContract: React.FC<Props> = ({ contract }) => {
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const dispatch = useAppDispatch();
  const users = useSelector(selectUsers);
  const createdByUser = users[contract.createdBy];

  function openClientBlade() {
    const userCanViewContracts = checkContractPermissions(ActionType.READ, UIActionType.ONCLICK)
    if (userCanViewContracts) {
      dispatch(setCurrentContractId(contract._id))
      dispatch(setBladeMode("contractViewEdit"))
    }
  }

  return (
    <ListItemButton
      onClick={() => openClientBlade()}
      sx={(theme) => ({
        py: 2,
        borderBottom: `1px solid ${theme.palette.divider}`
      })}
    >
      <ListItemText
        primary={`# ${contract.projectNumber ? contract.projectNumber : "No Project Number"}`}
        secondary={(
          <Typography
            variant="body2"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "primary",
            }}
          >
            {contract.assetDeliverables.length} Assets - {contract.contractType === "Rental" ? "Rented" : "Sold"} by {getUserFullName(createdByUser)}
          </Typography>
        )}
      />
      <ListItemSecondaryAction>
        <Typography
          variant="subtitle2"
          sx={{
            mt: 0.5,
            display: "flex",
            alignItems: "center",
            color: "primary",
          }}
        >
          {contract.status}
        </Typography>
      </ListItemSecondaryAction>
    </ListItemButton>
  );
};
export default ClientContract;
