import { sendToBackend } from "store/api/util";
import { Branch } from "store/models/Branch";
import { errorHandler } from "./shared";
import { createMongoId } from "utils/createMongoId";
import { defaultAddress } from "store/models/Address";
import { initCurrency } from "types/Currency";

const branchesApi = {
  getAll: (actions?: {
    onData?: (data: Branch[]) => void;
    onError?: (message: string) => void;
    onComplete?: () => void;
    currentlySubmitting?: (isSubmitting: boolean) => void;
  }) => {
    sendToBackend<Branch[]>({
      route: "branch/",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  updateOne: (
    updatedBranch: Branch,
    actions?: {
      onData?: (data: Branch) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Branch>({
      route: `branch/`,
      method: "PUT",
      body: updatedBranch,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    })
  },
};

export const initBranch = (branch?: Partial<Branch>): Branch => ({
  _id: createMongoId(),
  name: "",
  address: defaultAddress,
  contact: "",
  yards: [],
  country: "Canada",
  labourCost: initCurrency(),
  phone: "",
  fax: "",
  costCenter: "",
  inspectionToCreateForOutMovements: '',
  inspectionToCreateForInMovements: '',
  onRentInspectionDueDateOffset: 0,
  offRentInspectionDueDateOffset: 0,
  replaceAssetPhotosOnOutInspection: false,
  defaultUserAssignedToOutInspection: null,
  defaultUserAssignedToInInspection: null,
  ...branch,
})

export default branchesApi;
