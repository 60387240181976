import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Fab,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { selectCurrentClientId } from "store/slices/clientSlice";
import { useSelector } from "react-redux";
import Search from "@mui/icons-material/Search";
import { ClientContact, initClientContact } from "store/models/ClientContact";
import {
  getFullName,
  wordOrderInsensitivePartialObjectMatch,
} from "utils/util";
import Add from "@mui/icons-material/Add";
import ClientContactBlade from "features/clients/contacts/ClientContactBlade";
import { FormikHelpers } from "formik";
import {
  useCreateClientContactMutation,
  useGetClientContactByQueryQuery,
  useUpdateClientContactMutation,
} from "store/services/clientContact";
import { ClientContactBladeMode } from "features/clients/contacts/ClientContacts";
import { useGetClientByIdQuery } from "store/services/client";
import LoadingComponent from "components/LoadingComponent";

interface Props {}
const ClientPersonnel: React.FC<Props> = () => {
  const currentClientId = useSelector(selectCurrentClientId);
  const {data: currentClient} = useGetClientByIdQuery(currentClientId || "", {skip: !currentClientId});
  const [createClientContact] = useCreateClientContactMutation();
  const [updateClientContact] = useUpdateClientContactMutation();
  const { data: clientContacts = [], isLoading } =
    useGetClientContactByQueryQuery({ currentClient: currentClient?._id }, { skip: !currentClient });
  const [searchText, setSearchText] = useState("");
  const [showClientContactBlade, setShowClientContactBlade] = useState(false);
  const [selectedClientContact, setSelectedClientContact] =
    useState<ClientContact>(initClientContact({}));
  const [mode, changeMode] = useState<ClientContactBladeMode>("view");

  const visibleContacts = clientContacts.filter(
    ({ _id, currentClient, ...rest }) =>
      wordOrderInsensitivePartialObjectMatch(searchText, rest)
  );

  const handleNewContactClick = () => {
    setSelectedClientContact(initClientContact({}));
    changeMode("new");
    setShowClientContactBlade(true);
   
  };
  const handleClientContactClick = (clientContact: ClientContact) => {
    setSelectedClientContact(clientContact);
    changeMode("edit")
    setShowClientContactBlade(true);
  };

  const handleSubmit = (
    values: ClientContact,
    formikHelpers: FormikHelpers<ClientContact>
  ) => {
    if (mode === "new") {
      createClientContact(values)
        .unwrap()
        .then(() => {
          setShowClientContactBlade(false);
          formikHelpers.resetForm();
          formikHelpers.setSubmitting(false);
        })
        .catch(() => {
          formikHelpers.setSubmitting(false);
        });
    } else {
      updateClientContact(values)
        .unwrap()
        .then(() => {
          setShowClientContactBlade(false);
          formikHelpers.resetForm();
          formikHelpers.setSubmitting(false);
        })
        .catch(() => {
          formikHelpers.setSubmitting(false);
        });
    }
  };

  function getEmptyListText() {
    switch (true) {
      case !clientContacts.length:
        return "Client does not have any Personnel added to Starfleet yet";
      case Boolean(searchText):
        return "No Personnel Match Current Filters";
      default:
        return "No Personnel Match Current Filters";
    }
  }
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: 1,
            positon: "sticky",
            top: 0,
          }}
        >
          <TextField
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            fullWidth
            InputProps={{
              endAdornment: <Search />,
            }}
            sx={{
              flex: 3,
            }}
          />
          <Fab sx={{}} size="small" onClick={handleNewContactClick}>
            <Add />
          </Fab>
        </Box>
        <LoadingComponent isLoading={isLoading}>
          <List>
            {visibleContacts.length ? (
              visibleContacts.map((contact) => (
                <ListItemButton
                  disabled={false}
                  sx={(theme) => ({
                    py: 2,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  })}
                  key={contact._id}
                  onClick={() => handleClientContactClick(contact)}
                >
                  <ListItemText
                    primary={`${getFullName(contact)} - ${contact.companyRole}`}
                    sx={{
                      textWrap: "wrap",
                      maxWidth: "60%",
                      wordWrap: "break-word",
                    }}
                    secondary={
                      <Typography
                        variant="body2"
                        sx={{
                          mt: 0.5,
                          display: "flex",
                          alignItems: "center",
                          color: "primary",
                        }}
                      >
                        {contact.email}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          mt: 0.5,
                          display: "flex",
                          alignItems: "center",
                          color: "primary",
                        }}
                      >
                        P: {contact.primaryPhone}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          mt: 0.5,
                          display: "flex",
                          alignItems: "center",
                          color: "primary",
                          minHeight: 22,
                        }}
                      >
                        {contact.altPhone ? `Alt: ${contact.altPhone}` : ""}
                      </Typography>
                    </Box>
                  </ListItemSecondaryAction>
                </ListItemButton>
              ))
            ) : (
              <ListItem>
                <ListItemText>
                  {getEmptyListText()}
                </ListItemText>
              </ListItem>
            )}
          </List>
        </LoadingComponent>
      </Box>
      {showClientContactBlade && (
        <ClientContactBlade
          open={showClientContactBlade}
          handleSubmit={handleSubmit}
          handleClose={() => setShowClientContactBlade(false)}
          clientContact={selectedClientContact}
          clientId={currentClient?._id}
          bladeStyle={{ margin: 0 }}
          mode={mode}
          changeMode={changeMode}
        />
      )}
    </>
  );
};
export default ClientPersonnel;
