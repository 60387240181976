import { useSelector } from 'react-redux';
import { useGetAssetsByContractIdQuery } from 'store/services/asset';
import { useGetContractByIdQuery } from 'store/services/contract';
import { selectCurrentContractId } from 'store/slices/contractSlice';
import { byIds } from 'store/sliceUtils';

const useContractBladeTabNotifications = (): {
  detailsTabNotifications: number;
  assetsTabNotifications: number;
  invalidContractProperties: string[];
} => {

  const currentContractId = useSelector(selectCurrentContractId);
  const {data: currentContract} = useGetContractByIdQuery(currentContractId || "", {skip: !currentContractId});
  const {data: assets = []} = useGetAssetsByContractIdQuery(currentContractId || "", {skip: !currentContractId});
  const assetsHash = byIds(assets);

  let notifications = {
    detailsTabNotifications: 0,
    assetsTabNotifications: 0,
  };

  let invalidContractProperties = []

  if (["AWAITING CONFIRMATION", "PENDING DELIVERY", "ACTIVE"].includes(currentContract?.status || "")) {
    if (!currentContract?.projectNumber) {
      notifications.detailsTabNotifications++;
      invalidContractProperties.push("projectNumber")
    }

    if (currentContract?.contractSigned === false) {
      notifications.detailsTabNotifications++;
      invalidContractProperties.push("contractSigned")
    }

    for (const deliverable of currentContract?.assetDeliverables || []) {
      if (currentContract?.contractType === "Rental" && !deliverable.rentalRate.amount ) {
        notifications.assetsTabNotifications++;
        invalidContractProperties.push("rentalRate")
      }
    }

    for (const deliverable of currentContract?.assetDeliverables || []) {
      if (currentContract?.contractType === "Sale" && !deliverable.saleAmount.amount) {
        notifications.assetsTabNotifications++;
        invalidContractProperties.push("saleAmount")
      }
    }
  }

  if (currentContract?.status === "PENDING DELIVERY") {
    for (const deliverable of currentContract.assetDeliverables) {
      if (assetsHash[deliverable.asset]?.status === "SOLD PENDING DELIVERY") {
        notifications.assetsTabNotifications++;
      }
    }
  }

  if (currentContract?.status === "ACTIVE") {
    for (const deliverable of currentContract.assetDeliverables) {
      if (assetsHash[deliverable.asset]?.status === "ON LEASE") {
        notifications.assetsTabNotifications++;
      }
    }
  }

  return {...notifications, invalidContractProperties};
};

export default useContractBladeTabNotifications;
