import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Branch } from "store/models/Branch";
import api from "store/api";
import { STARFLEET_STORE } from "store/store";
import { byIds } from "store/sliceUtils";
import { Yard } from "store/models/Yard";

export type OPTIONS_STORE = {
  branches: { [_id: string]: Branch };
  branchesLoading: boolean;
  yards: { [_id: string]: Yard };
  yardsLoading: boolean;
};

const initialState: OPTIONS_STORE = {
  branches: {},
  branchesLoading: true,
  yards: {},
  yardsLoading: true,
};

const optionSlice = createSlice({
  name: "option",
  initialState,
  reducers: {
    setBranches(state, action: PayloadAction<Branch[]>) {
      state.branches = byIds(action.payload);
    },
    setOneBranch(state, action: PayloadAction<Branch>) {
      state.branches[action.payload._id] = action.payload
    },
    setYards(state, action: PayloadAction<Yard[]>) {
      state.yards = byIds(action.payload);
    },
    setOneYard(state, action: PayloadAction<Yard>) {
      state.yards[action.payload._id] = action.payload
    },
    setBranchesLoading(state, action: PayloadAction<boolean>) {
      state.branchesLoading = action.payload;
    },
    setYardsLoading(state, action: PayloadAction<boolean>) {
      state.yardsLoading = action.payload;
    }
  },
});


export const fetchAllBranches = () => {
  return (dispatch: Function) => {
    api.branches.getAll({
      onData: (data) => {
        dispatch(setBranches(data));
        dispatch(setBranchesLoading(false));
      },
    });
  };
};

export const fetchAllYards = () => {
  return (dispatch: Function) => {
    api.yards.getAll({
      onData: (data) => {
        dispatch(setYards(data));
        dispatch(setYardsLoading(false));
      },
    });
  };
};

export const selectBranches = (state: STARFLEET_STORE) => state.options.branches;
export const selectYards = (state: STARFLEET_STORE) => state.options.yards;

export const { setBranches, setOneBranch, setYards, setOneYard, setBranchesLoading, setYardsLoading } = optionSlice.actions;

export default optionSlice.reducer;
