import { FC, useState } from "react";
import {
  Box,
  Button, ButtonProps, IconButton, InputAdornment, TextField,
  TextFieldProps,
} from "@mui/material";
import Address, { defaultAddress } from "store/models/Address";
import Add from "@mui/icons-material/Add";
import AddressSelectDialog from "./AddressSelectDialog";
import { displayAddress } from "utils/util";
import EditOutlined from "@mui/icons-material/EditOutlined"

interface Props {
  onAddressSelect: (address: Address) => void;
  buttonProps?: ButtonProps;
  address?: Address;
  buttonText?: string;
  showAddress?: boolean
  restrictToCountry?: "ca" | "us"
  size?: TextFieldProps["size"]
}

const AddressInput: FC<Props> = ({
  onAddressSelect,
  buttonProps,
  buttonText = "New",
  address = defaultAddress,
  showAddress = false,
  restrictToCountry,
  size = "medium"
}) => {
  const [show, changeShow] = useState(false);

  return (
    <Box>
      {showAddress ? (
        <TextField
          fullWidth
          label="Address"
          size={size}
          value={displayAddress(address)}
          InputProps={{
            size,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => changeShow(true)}
                  edge="end"
                >
                  <EditOutlined />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <Button
          startIcon={<Add />}
          onClick={() => changeShow(true)}
          {...buttonProps}
        >
          {buttonText}
        </Button>
      )}
        <AddressSelectDialog
          address={address}
          show={show}
          changeShow={changeShow}
          onAddressSelect={(address) => {
            onAddressSelect(address);
            changeShow(false);
          }}
          restrictToCountry={restrictToCountry}
        />
    </Box>
  );
};

export default AddressInput;
