import React, { FC, useState } from "react";
import {
  Autocomplete,
  AutocompleteProps,
  createFilterOptions,
  TextField,
} from "@mui/material";
import { Contract } from "store/models/Contract";
import { useGetContractAutocompleteOptionsQuery } from "store/services/contract";
import useDebounce from "hooks/useDebounce";
import _ from "lodash";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (contract: Contract) => `${contract.customerName} ${contract.projectNumber}`,
});

export type ContractAutoCompleteProps = Omit<
  AutocompleteProps<Contract, boolean, boolean, boolean>,
  "options" | "renderInput"
> & {
  name?: string;
  error?: boolean;
  label?: string;
  "data-testId"?: string
  options?: AutocompleteProps<Contract, boolean, boolean, boolean>["options"]
};

const ContractAutocomplete: FC<ContractAutoCompleteProps> = ({ value, name, error, label, options: limitedOptions, ...rest }) => {

  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const {data: contractOptions = []} = useGetContractAutocompleteOptionsQuery({searchValue: debouncedSearchValue, limit: 50}, {skip: !debouncedSearchValue || Boolean(limitedOptions?.length)});
  const options = limitedOptions || contractOptions

  return (
    <Autocomplete
      options={_.sortBy(options, "customerName")}
      onInputChange={(event, value) => setSearchValue(value)}
      {...rest}
      value={value || null}
      getOptionLabel={(contract) => typeof contract === "string" ? contract : `${contract?.customerName} (${contract?.projectNumber})`} 
      filterOptions={filterOptions}
      clearOnBlur={false}
      renderInput={(params) => (
        <TextField
          name={name}
          {...params}
          data-testid={rest["data-testId"]}
          error={error}
          margin="none"
          label={label}
          inputProps={{ ...params.inputProps, autocomplete: "new-password", form: { autocomplete: "off" } }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option._id}>
            {`${option?.customerName} (${option?.projectNumber})`}
          </li>
        );
      }}
    />
  );
}

export default ContractAutocomplete;