import { FC } from "react";
import { Page, View, Text, Image, Document, StyleSheet,  } from '@react-pdf/renderer';
import { fCurrencyFloat } from "utils/formatNumber";
import { getFullName, toDDMMMYYYY, toMMDDYYYY } from "utils/util";
import { Branch } from "store/models/Branch";
import { Asset } from "store/models/Asset";
import { Contract } from "store/models/Contract";
import { Client } from "store/models/Client";
import UserData from "store/models/UserData";
import { WebWOQueryResponse } from "store/services/workOrder";
import { ClientContact } from "store/models/ClientContact";
import PDFImages from "components/pdf/PDFImages";
import PDFHeader from "components/pdf/PDFHeader";


interface Props {
  user: UserData;
  asset: Asset;
  contract?: Contract;
  client: Client;
  generatedWorkOrders: WebWOQueryResponse[];
  labourCost: Branch["labourCost"];
  clientContact: ClientContact[];
  workOrder?: WebWOQueryResponse;
  images: Array<string>;
  branch: Branch;
}

const ServiceDamageReport: FC<Props> = ({
  user,
  generatedWorkOrders,
  labourCost,
  asset,
  contract,
  client,
  clientContact,
  workOrder,
  images,
  branch
}) => {

  const workOrdersBillableToClient = generatedWorkOrders.filter((workOrder) => workOrder.billableToClient);

  const workOrderEstimatedCost = (workOrder: WebWOQueryResponse) => {
    return labourCost.amount * (workOrder.estimatedHours || 0) + (workOrder.estimatedMaterials?.amount || 0)
  }

  const totalCost = workOrdersBillableToClient.reduce((totalCost, workOrder) => {
    totalCost += workOrderEstimatedCost(workOrder);
    return totalCost;
  }, 0);

  const isUSA = branch?.country === "USA";

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PDFHeader isUSA={isUSA} branch={branch} />
        <View style={[styles.table, { borderLeftWidth: 0 }]}>
          <View style={styles.tableBody}>
            <View style={[styles.tableRow, { minHeight: 38 }]}>
              <View style={styles.detailColumn}>
                <Text style={[styles.subtitle2]}>Client: {client?.companyName}</Text>
              </View>
              <View style={styles.detailColumn}>
                <Text style={[styles.subtitle2]}>
                  Unit Type: {asset?.sizeCode} {asset?.category}
                </Text>
              </View>
            </View>

            <View style={[styles.tableRow, { minHeight: 38 }]}>
              <View style={styles.detailColumn}>
                <Text style={[styles.subtitle2]}>Attention To: {clientContact[0] ? getFullName(clientContact[0]) : "No Main Contact"}</Text>
              </View>
              <View style={styles.detailColumn}>
                <Text style={[styles.subtitle2]}>Phone #: {clientContact[0] ? clientContact[0].primaryPhone : "No Main Contact"}</Text>
              </View>
            </View>

            <View style={[styles.tableRow, { minHeight: 38 }]}>
              <View style={styles.detailColumn}>
                <Text style={[styles.subtitle2]}>Email: {clientContact[0] ? clientContact[0].email : "No Main Contact"}</Text>
              </View>
              <View style={styles.detailColumn}>
                <Text style={[styles.subtitle2]}>Client PO: {contract?.clientPoNumber || "No Client PO Number"} </Text>
              </View>
            </View>

            <View style={[styles.tableRow, { minHeight: 38 }]}>
              <View style={styles.detailColumn}>
                <Text style={[styles.subtitle2]}>ATCO Work Order NO.: {workOrder?.number}</Text>
              </View>
              <View style={styles.detailColumn}>
                <Text style={[styles.subtitle2]}>BID Date: {toMMDDYYYY(new Date())}</Text>
              </View>
            </View>

            <View style={[styles.tableRow, { minHeight: 38 }]}>
              <View style={styles.detailColumn}>
                <Text style={[styles.subtitle2]}>Serial NO.: {asset?.serialNumber}</Text>
              </View>
              <View style={styles.detailColumn}>
                <Text style={[styles.subtitle2]}>Project #: {contract?.projectNumber || "No Project Number"}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableRow}>
              <View style={styles.descriptionColumn}>
                <Text style={styles.subtitle2}>Description</Text>
              </View>
              <View style={styles.hoursColumn}>
                <Text style={styles.subtitle2}>Hours</Text>
              </View>
              <View style={styles.materialColumn}>
                <Text style={styles.subtitle2}>Materials</Text>
              </View>
              <View style={[styles.costColumn, styles.alignCenter]}>
                <Text style={styles.subtitle2}>Total Cost</Text>
              </View>
            </View>
          </View>
          <View style={styles.tableBody}>
            {workOrdersBillableToClient.length ? workOrdersBillableToClient.map((item) => (
              <View style={styles.tableRow} key={item._id}>
                <View style={styles.descriptionColumn}>
                  <Text style={styles.text}>
                    {item.title || item.description}
                  </Text>
                </View>
                <View style={styles.hoursColumn}>
                  <Text style={styles.text}>
                    {item.estimatedHours || 0}
                  </Text>
                </View>
                <View style={styles.materialColumn}>
                  <Text style={styles.text}>
                    {fCurrencyFloat(
                      item.estimatedMaterials?.amount || 0
                    )}
                  </Text>
                </View>
                <View style={[styles.costColumn, styles.alignCenter]}>
                  <Text style={styles.text}>
                    {fCurrencyFloat(workOrderEstimatedCost(item))}
                  </Text>
                </View>
              </View>
            )) : (
            <View style={styles.tableRow} key="missing-work-orders">
              <Text style={[styles.text, { marginTop: 6, width: "100%", textAlign: "center" }]}>
                No Work Orders Generated From Inspection 
              </Text>
            </View>
            )}
          </View>
        </View>

        <View
          style={{
            marginVertical: 4,
            gap: 2,
          }}
        >
          <View style={{ alignItems: "flex-end" }}>
            <Text style={[{ width: "15%" }]}>
              Hourly Rate: {fCurrencyFloat(labourCost.amount)}
            </Text>
          </View>
          <View style={{ alignItems: "flex-end" }}>
            <Text style={[styles.h4, { width: "15%" }]}>
              Total: {fCurrencyFloat(totalCost)}
            </Text>
          </View>
        </View>
        <View
          style={{
            marginVertical: 4,
            gap: 4,
            width: "50%",
            flexDirection: "column",
          }}
        >
          <View style={[styles.flexContainer, { width: "100%", alignItems: undefined }]}>
            <Text style={[styles.h4]}>
              Print Name:
            </Text>
            <Text style={[{ paddingTop: 6, borderBottom: "1px solid black", flex: 1 }]} />
          </View>
          <View style={[styles.flexContainer, { width: "100%", alignItems: undefined }]}>
            <Text style={[styles.h4]}>
              Signature:
            </Text>
            <Text style={[{ paddingTop: 6, borderBottom: "1px solid black", flex: 1 }]} />
          </View>
        </View>
        
        <View style={[styles.alignCenter, { marginTop: 26 }]}>
          <Text style={styles.text}>Please sign and email a copy of this report back to the following contact</Text>
        </View>
        <View style={[styles.alignCenter]}>
          <Text style={[{ fontStyle: "bold", fontSize: 12 }]}>{user.email}</Text>
        </View>

        <View style={[styles.alignCenter, { border: "1px solid black", padding: 16, marginHorizontal: 20, marginTop: 20 }]}>
          <Text style={[styles.text, { fontStyle: "italic" }]}>This report is issued in accordance with the terms of the agreement.</Text>
          <Text style={styles.text}>If we have not received a response back within 5 business days of this notice being sent, an invoice will be sent for the charges</Text>
        </View>
        <View style={[styles.alignCenter, styles.footer]}>
          <Text style={styles.subtitle2}>{toDDMMMYYYY(new Date())}</Text>
        </View>
        {images.length ? <PDFImages images={images} startOnNewPage /> : null}
      </Page>
    </Document>
  );
};

export default ServiceDamageReport;


const styles = StyleSheet.create({
  col4: { width: "25%" },
  col8: { width: "75%" },
  col6: { width: "50%" },
  mb8: { marginBottom: 8 },
  overline: {
    fontSize: 8,
    marginBottom: 8,
    fontWeight: 700,
    letterSpacing: 1.2,
    textTransform: "uppercase",
  },
  h3: { fontSize: 16, fontWeight: 700 },
  h4: { fontSize: 13, fontWeight: 700 },
  body1: { fontSize: 10 },
  text: { 
    fontSize: 9,
    marginHorizontal: 12
  },
  subtitle2: { fontSize: 9, fontWeight: 700, marginHorizontal: 12 },
  alignRight: { textAlign: "right" },
  alignCenter: { textAlign: "center" },
  alignLeft: { textAlign: "left" },
  page: {
    padding: "40px 24px 0 24px",
    fontSize: 9,
    lineHeight: 1.6,
    backgroundColor: "#fff",
    textTransform: "capitalize",
  },
  footer: {
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    position: "absolute",
  },
  gridContainer: { flexDirection: "row", justifyContent: "space-between" },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  noBorder: { paddingTop: 8, paddingBottom: 0, borderBottomWidth: 0 },
  table: {
    width: "100%",
    fontSize: 12,
    marginVertical: 4,
    borderRight: "1px solid black",
    borderTop: "1px solid black",
    borderLeft: "1px solid black",
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: "#80808080",
  },
  tableBody: {},
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "black",
  },
  tableTitle: {
    width: "100%",
    fontSize: 12,
    padding: "6px 12px",
    paddingBottom: 0,
  },
  detailColumn: {
    height: "100%",
    // display: "flex",
    justifyContent: "center",
    paddingVertical: 2,
    width: "50%",
    borderLeft: "1px solid black",
  },
  descriptionColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingVertical: 2,
    width: "55%",
    marginLeft: 4,
  },
  hoursColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingVertical: 2,
    borderLeft: "1px solid black",
    width: "15%",
    paddingRight: 16,
  },
  materialColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingVertical: 2,
    borderLeft: "1px solid black",
    width: "15%",
  },
  costColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingVertical: 2,
    borderLeft: "1px solid black",
    width: "25%",
  },
  underline: {
    borderBottom: "1px solid black"
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: 20
  },
  image: {
    height: 300,
  },
});
