import LoadingScreen from "components/LoadingScreen";
import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectStoreLoading,
} from "store/slices/systemSlice";
import SideNav from "components/side-nav/SideNav";
import Modals from "components/modals/index";
import Blades from "components/Blades";
import DashboardHeader from "layouts/header";
import { styled } from "@mui/material/styles";
import { DASHBOARD_HEADER_DESKTOP, DASHBOARD_HEADER_MOBILE, DASHBOARD_NAVBAR_COLLAPSE_WIDTH, DASHBOARD_NAVBAR_WIDTH } from "config";
import useCollapseDrawer from "hooks/useCollapseDrawer";
import { Outlet } from "react-router-dom";
import { useAppDispatch } from "store/store";
import { refreshData, refreshOptionsData } from "utils/util";
import SocketContextProvider from "contexts/SocketContext";
import StarfleetLoading from "components/StarfleetLoading";

type LayoutStyleProps = {
  collapseClick: boolean;
};

const LayoutStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<LayoutStyleProps>(({ collapseClick, theme }) => ({
  paddingTop: DASHBOARD_HEADER_MOBILE,
  paddingBottom: DASHBOARD_HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: DASHBOARD_HEADER_DESKTOP,
    paddingBottom: DASHBOARD_HEADER_DESKTOP,
    marginLeft: collapseClick ? DASHBOARD_NAVBAR_COLLAPSE_WIDTH : DASHBOARD_NAVBAR_WIDTH,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
  }, 
  height: "100%",
}));

const Starfleet = () => {
  const { collapseClick } = useCollapseDrawer();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const storeIsLoading = useSelector(selectStoreLoading);


  useEffect(() => {
    refreshOptionsData(dispatch);
    refreshData(dispatch)
  }, []);




  
  return storeIsLoading ? (
    <LoadingScreen />
  ) : (
    <SocketContextProvider>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} />
        <SideNav
          isOpenSidebar={open}
          onCloseSidebar={() => {
            setOpen(false);
          }}
        />
        <LayoutStyle collapseClick={collapseClick}>
          <Suspense fallback={<StarfleetLoading />}>
            <Outlet />
          </Suspense>
        </LayoutStyle>
        <Blades />
        <Modals />
    </SocketContextProvider>
  );
};

export default Starfleet;
