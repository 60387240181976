import { ReactNode } from 'react';
import isString from 'lodash/isString';
import { Box, Typography, Link, useMediaQuery, useTheme } from '@mui/material';
import Breadcrumbs, { Props as BreadcrumbsProps }  from 'components/header/Breadcrumbs';

interface Props extends BreadcrumbsProps {
  action?: ReactNode;
  heading: string;
  moreLink?: string | string[];
}

function PageHeader({
  links,
  action,
  heading,
  moreLink = '',
  ...other
}: Props) {
  const theme = useTheme();
  const belowMDScreen =  useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={(theme) => ({
        mb: 5,
        [theme.breakpoints.down("xl")]: {
          mb: 0,
        },
      })}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: belowMDScreen ? "flex-start" : "center",
          justifyContent: "space-between",
          flexDirection: belowMDScreen ? "column" : "row",
          gap: 2,
        }}
      >
        <Box>
          <Typography variant="h4" gutterBottom>
            {heading}
          </Typography>
          <Breadcrumbs links={links} {...other} />
        </Box>

        {action && <Box sx={{ display: "flex", gap: 1 }}>{action}</Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: "table" }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
export default PageHeader;
