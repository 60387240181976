import { View, Image, Text } from "@react-pdf/renderer";
import React from "react";
import { Branch } from "store/models/Branch";

interface Props {
    isUSA: boolean;
    branch: Branch

}

const PDFHeader: React.FC<Props> = ({isUSA, branch}) => {
  return (
    <View
      style={{
        marginHorizontal: 8,
        marginBottom: 20,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      fixed
    >
      <Image source="/atco-logo.png" style={{ height: 32 }} />
      <View style={{ alignItems: "flex-end", flexDirection: "column", gap: 0 }}>
        <Text style={{ fontSize: 12, fontFamily: "Roboto", fontWeight: 800 }}>
          {isUSA
            ? "ATCO Structures & Logistics USA Ltd"
            : "ATCO Structures & Logistics Ltd"}
        </Text>
        <Text style={{ fontSize: 9, fontFamily: "Roboto" }}>
          {branch?.address.address}
        </Text>
        <Text style={{ fontSize: 9, fontFamily: "Roboto" }}>
          T: {branch?.phone}
        </Text>
        <Text style={{ fontSize: 9, fontFamily: "Roboto" }}>
          {branch?.fax ? `F: ${branch.fax}` : ""}
        </Text>
        <Text style={{ fontSize: 9, fontFamily: "Roboto" }}>
          https://structures.atco.com
        </Text>
      </View>
    </View>
  );
};

export default PDFHeader;
