import React, { FC, ReactNode } from "react";
import { ReactComponent as BillOfLadingIcon } from "svg/tasks.svg";
import { setModalMode } from "store/slices/systemSlice";
import { useSelector } from "react-redux";
import { dateFromMMDDYYYY, toDDMMMYYYY } from "utils/util";
import {
  selectCurrentMovementId,
  selectMovementContractId,
  setBillOfLadingMovementId,
  setCurrentBillOfLadingId,
} from "store/slices/contractSlice";
import usePermissions, { FeatureType, ActionType } from 'hooks/usePermissions';
import { Box, Typography, Card, IconButton, SvgIcon, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { useAppDispatch } from 'store/store';
import { selectBranches } from "store/slices/optionSlice";
import { fPotentiallyUndefinedCurrency } from "utils/formatNumber";
import { updateMovementQueryData, useGetMovementByIdQuery, useUpdateNonContractMovementMutation } from "store/services/movement";
import { thirtySeconds } from "utils/timeValues";
import { selectMovementQueryArgs } from "store/slices/movementSlice";
import Movement, { MovementStatus } from "store/models/Movement";
import { useUpdateMovementStatusMutation } from "store/services/contract";

interface MovementRowType {
  label: string;
  value: ReactNode;
}

const MovementRow: FC<MovementRowType> = ({ 
  label,
  value, }) => {
  return(
    <Box display="flex" justifyContent="space-between" py={2} alignContent="center">
      <Typography>{label}</Typography>
      {value}
    </Box>
  )
}

interface Props {}

const MovementView: React.FC<Props> = () => {
  const currentMovementId = useSelector(selectCurrentMovementId);
  const currentContractId = useSelector(selectMovementContractId)
  const {
    data: movement,
  } = useGetMovementByIdQuery(
    {
      movementId: currentMovementId || "",
      contractId: currentContractId || "",
    },
    { skip: !currentMovementId, pollingInterval: thirtySeconds }
  );
  const [updateNonContractMovement] = useUpdateNonContractMovementMutation()
  const branches = useSelector(selectBranches);
  const dispatch = useAppDispatch();
  const checkBillOfLadingPermissions = usePermissions(FeatureType.BILL_OF_LADING);
  const userCanViewBOL = checkBillOfLadingPermissions(ActionType.READ);
  const checkMovementsPermissions = usePermissions(FeatureType.MOVEMENT);
  const userCanEditMovements = checkMovementsPermissions(ActionType.LIMITED_UPDATE)
  const movementQueryArgs = useSelector(selectMovementQueryArgs);
  const [updateMovementStatusMutation] = useUpdateMovementStatusMutation()

  function isEditing(){
    return Boolean(movement?.billOfLading)
  }

  const handleBillOfLadingClick = () => {
    dispatch(setBillOfLadingMovementId(movement?._id));
    dispatch(setCurrentBillOfLadingId(movement?.billOfLading));

    if(isEditing()){
      dispatch(setModalMode("billOfLadingViewEdit"))

    } else {
      dispatch(setModalMode("billOfLadingCreate"));
    }
  }

  const updateMovementStatus = (
    __: React.MouseEvent<HTMLElement, MouseEvent>,
    status: MovementStatus
  ) => {
    if (!status || !movement || status === movement.status) return;

    if (movement.contract) {
      updateMovementStatusMutation({
        contractId: movement.contract._id,
        movementId: movement._id,
        newStatus: status,
      })
        .unwrap()
        .then(({ contract, assets }) => {
          dispatch(
            updateMovementQueryData(movement._id, { status }, movementQueryArgs)
          );
        });
    } else {
      updateNonContractMovement({
        ...movement,
        asset: movement.asset?._id,
        accessory: movement.accessory?._id,
        contract: undefined,
        status,
      } as Movement);
    }
  };

  function movementBranch() {
    return movement && branches[movement.branch] ? branches[movement.branch].name : ""
  }

  return (
    <Card sx={{ p: 3 }}>
      <MovementRow label="Branch" value={movementBranch()} />

      {userCanEditMovements ? (
        <ToggleButtonGroup
          sx={{ mt: 1 }}
          fullWidth
          value={movement?.status}
          exclusive
          onChange={updateMovementStatus}
          color="primary"
        >
          {Object.values(MovementStatus).map((status) => {
            return (
              <ToggleButton
                key={status}
                value={status}
                data-testid={`status-${status}`}
              >
                {status}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      ) : (
        <MovementRow label="Status" value={movement?.status} />
      )}

      <MovementRow
        label="Date Of Movement"
        value={
          movement?.dateOfMove
            ? toDDMMMYYYY(dateFromMMDDYYYY(movement?.dateOfMove))
            : "..."
        }
      />

      <MovementRow label="Tentative?" value={movement?.tentative ? "Yes" : "No"} />

      <MovementRow label="Lease Takeover?" value={movement?.leaseTakeover ? "Yes" : "No"} />

      <MovementRow label="Type" value={movement?.type} />

      <MovementRow
        label="Transport Company"
        value={movement?.transportCompanyName}
      />

      <MovementRow
        label="Transport Invoice #"
        value={movement?.transportInvoiceNumber}
      />

      <MovementRow label="Batch #" value={movement?.batch} />

      <MovementRow
        label="Bill of Lading"
        value={
          <IconButton
            onClick={handleBillOfLadingClick}
            disabled={!userCanViewBOL}
          >
            <SvgIcon
              color={movement?.billOfLading ? "primary" : "disabled"}
              fontSize="small"
            >
              <BillOfLadingIcon />
            </SvgIcon>
          </IconButton>
        }
      />
          <MovementRow label="PO #" value={movement?.purchaseOrder?.poNumber} />
          <MovementRow label="PO Amount" value={fPotentiallyUndefinedCurrency(movement?.purchaseOrder?.amount)} />
          <MovementRow label="Bid Cost" value={fPotentiallyUndefinedCurrency(movement?.bidCost)} />
          <MovementRow label="Actual Cost" value={fPotentiallyUndefinedCurrency(movement?.actualCost)} />
          <MovementRow label="Notes:" value="" />
          <Typography>{movement?.notes}</Typography>
    </Card>
  );
};
export default MovementView;
