import { FC } from "react";
import { Page, View, Text, Document, StyleSheet, Font,  } from '@react-pdf/renderer';
import { Branch } from "store/models/Branch";
import { Asset } from "store/models/Asset";
import PDFHeader from "components/pdf/PDFHeader";
import { WorkOrderStatus } from "store/models/WorkOrder";
import { AssetWorkOrder } from "store/services/workOrder";
import { Yard } from "store/models/Yard";
import PDFImages from "components/pdf/PDFImages";
import PDFFooter from "components/pdf/PDFFooter";



Font.register({
  family: 'Roboto',
  fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf' }],
});


interface Props {
  asset: Asset;
  workOrders: AssetWorkOrder[];
  branch: Branch;
  yard: Yard;
  images: string[];

}

const AssetSummaryPDF: FC<Props> = ({ images, asset, branch, yard, workOrders }) => {
  const assetInfo = {
    "Serial Number": asset?.serialNumber,
    "Asset Number": asset?.assetNumber,
    'Status': asset?.status,
    'Category': asset?.category,
    'Sub Category': asset?.subCategory,
    "Size Code": asset?.sizeCode,
    'Branch': branch?.name,
    'Yard': yard?.name,
    'Floor Style': asset?.floorStyle,
    'Fuel Type': asset?.fuelType,
    'A/C Type': asset?.acType,
    'Manufacturer': asset?.manufacturer,
    'Structural Limit': asset?.structuralLimit,
    'Building Code': asset?.buildingCode,
    'Branding': asset?.branding,
    'Cladding': asset?.cladding,
    'Electrical': asset?.electrical,
    'Colour': asset?.colour,
    'Complex': asset?.complex,
    'Frame': asset?.frame,
    'Partitions': asset?.partitions,
    'Washrooms': asset?.wash,
    'Condition': asset?.condition,
    'Yard Position': asset?.yardPosition,
    'Layout': asset?.layout,
    'Key #': asset?.keyNumber,
    'License Plate': asset?.licensePlate,
    'Open WO Hrs': workOrders.reduce((acc, wo) => [WorkOrderStatus.TO_DO, WorkOrderStatus.IN_PROGRESS].includes(wo.status) ? acc + (wo.estimatedHours || 0) : acc, 0),
  };

  const isUSA = branch?.country === "USA";

  return (
    <Document style={{ height: "100%", width: "100%" }}>
      <Page size="A4" style={styles.page}>
        <PDFHeader isUSA={isUSA} branch={branch} />
        <View>
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {Object.entries(assetInfo).map(([label, value]) => (
              <InfoCell key={label} label={label} value={value} />
            ))}
          </View>
        </View>
        <View style={[styles.infoCell, {width: '100%'}]}>
          <Text style={{ fontWeight: 800, marginLeft: 4 }}>Notes:</Text>
          <Text wrap style={{ marginLeft: 4 }}>
            {asset.notes}
          </Text>
        </View>
        {images.length ? <PDFImages images={images} startOnNewPage /> : null}
        <PDFFooter />
      </Page>
    </Document>
  );
};


function InfoCell({
  label,
  value,
}: {
  label: string;
  value: string | number | undefined;
}) {
  return (
    <View
      style={styles.infoCell}
    >
      <Text style={{ fontWeight: 800, marginLeft: 4 }}>
        {label}:
      </Text>
      <Text wrap style={{marginLeft: 4}}>{value}</Text>
    </View>
  );
}


export default AssetSummaryPDF;

const styles = StyleSheet.create({
  page: {
    padding: "40px 24px 0 24px",
    fontSize: 9,
    lineHeight: 1.6,
    backgroundColor: "#fff",
    fontFamily: "Roboto",
    flex: 1,
    height: '100%',
  },
  infoCell: {
    flexDirection: "row",
    fontSize: 10,
    alignItems: "center",
    minHeight: 20,
    paddingTop: 3,
    width: "50%",
    fontFamily: 'Roboto',
    flexWrap: "wrap",
    border: '0.5px solid black'
  },
});

