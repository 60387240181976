import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";
import { BillOfLading } from "store/models/BillOfLading";
import { Contract } from "store/models/Contract";
import Movement from "store/models/Movement";

type BOLMutationPayload = {
  billOfLading: Partial<BillOfLading>;
  movementId: Movement["_id"];
  contractId?: Contract["_id"];

}

export const billOfLadingApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getBOLById: builder.query<BillOfLading, string>({
      query: (id) => `/billOfLading/${id}`,
      transformResponse: getResponseBody,
      providesTags: (result, error, id) => [
        { type: "BillOfLading", id: result?._id },
      ],
    }),
    updateBillOfLading: builder.mutation<BillOfLading, BOLMutationPayload>({
      query: (bol) => ({
        url: `/billOfLading`,
        method: "PATCH",
        body: bol,
      }),
      transformResponse: getResponseBody,
      invalidatesTags: (result, error, body) => [
        { type: "BillOfLading", id: result?._id },
      ],
      extraOptions: {
        maxRetries: 0,
        },
    }),
    createBillOfLading: builder.mutation<BillOfLading,BOLMutationPayload>({
      query: (bol) => ({
        url: `/billOfLading`,
        method: "POST",
        body: bol,
      }),
      transformResponse: getResponseBody,
      invalidatesTags: (result, error, {movementId}) => [
        { type: "BillOfLading", id: result?._id }, {type: "Movements", id: "LIST"}, {type: "Movements", id: movementId}
      ],
      extraOptions: {
        maxRetries: 0,
        },
    }),
  }),
});

export const { useGetBOLByIdQuery, useUpdateBillOfLadingMutation, useCreateBillOfLadingMutation } = billOfLadingApi;
