import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STARFLEET_STORE } from "store/store";


export interface CLIENTS_STORE {
    currentClientId: string | undefined;
    createdClientId: string | undefined;
};

const initialState: CLIENTS_STORE = {
    currentClientId: undefined,
    createdClientId: undefined
};

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setCurrentClientId(state, action: PayloadAction<string | undefined>) {
            state.currentClientId = action.payload;
        },
        setCreatedClientId(state, action: PayloadAction<string | undefined>) {
            state.createdClientId = action.payload;
        },
    }
})

export const selectCreatedClientId = (state: STARFLEET_STORE) => state.clients.createdClientId;

export const selectCurrentClientId = (state: STARFLEET_STORE) => state.clients.currentClientId



export const {
    setCurrentClientId,
    setCreatedClientId
} = clientSlice.actions;

export default clientSlice.reducer;
