import Blade from "components/Blade";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentContractId,
  setCurrentContractId,
} from "store/slices/contractSlice";
import ContractEdit from "./ContractForm";
import ContractView from "./ContractView";
import {
  selectBladeMode,
  selectLoggedInUser,
  simpleGlobalMessage,
} from "store/slices/systemSlice";
import { titleCase, useQuery } from "utils/util";
import BladeTabs from "components/BladeTabs";
import ContractStatusChangeButtons from "./ContractStatusChangeButtons";
import ContractAssets from "./assets/ContractAssets";
import useContractBladeTabNotifications from "features/contracts/useContractBladeTabNotifications";
import usePermissions, { ActionType, FeatureType } from "hooks/usePermissions";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import RevertIcon from "@mui/icons-material/History";
import ConfirmationModal from "components/ConfirmationModal";
import { Form, Formik } from "formik";
import { contractValidationSchema } from "utils/formikAPI";
import _ from "lodash";
import ContractAccessories from "./accessories/ContractAccessories";
import ContractHistory from "./ContractHistory";
import AddAssetModal, {
  AddAssetModalMode,
} from "components/modals/AddAssetModal";
import { AssetDeliverable } from "store/models/AssetDeliverable";
import { useAppDispatch } from "store/store";
import { selectDefaultUserCurrency } from "store/slices/configSlice";
import ContractModal from "components/modals/ContractModal";
import Fullscreen from "@mui/icons-material/Fullscreen";
import { Asset } from "store/models/Asset";
import Movement from "store/models/Movement";
import BulkMovementCreate from "features/contracts/BulkMovementCreate";
import useContractBladeUtils from "features/contracts/blades/useContractBladeUtils";
import ContractWorkOrders from "./ContractWorkOrders";
import { GridFilterModel } from "@mui/x-data-grid-pro";
import { initContract, useGetContractByIdQuery } from "store/services/contract";
import LoadingComponent from "components/LoadingComponent";
import { useGetClientByIdQuery } from "store/services/client";
import { oneMinute } from "utils/timeValues";

interface Props {}

const ContractViewEditBlade: React.FC<Props> = () => {
  const bladeMode = useSelector(selectBladeMode);
  const currentContractId = useSelector(selectCurrentContractId);

  const {data: currentContract, isFetching, currentData, isLoading} = useGetContractByIdQuery(currentContractId ||'', {skip: !currentContractId, pollingInterval: oneMinute});
  const {data: currentContractClient} = useGetClientByIdQuery(currentContract?.client ||'', {skip: !currentContract?.client});

  const {
    detailsTabNotifications,
    assetsTabNotifications,
    invalidContractProperties,
  } = useContractBladeTabNotifications();

  const query = useQuery();
  const loggedInUser = useSelector(selectLoggedInUser);
  const defaultCurrency = useSelector(selectDefaultUserCurrency(loggedInUser));

  const dispatch = useAppDispatch();
  const [showContractDetailsModal, setShowContractDetailsModal] =
    useState<boolean>(false);

  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const userCanRevertContracts = checkContractPermissions(ActionType.UPDATE);
  const defaultFilters: GridFilterModel = {
    items: [{field: "status", operator: "in-status", value: ["AVAILABLE"]}],
  };
  const [filterModel, setFilterModel] = useState<GridFilterModel>(defaultFilters);
  const [assetDeliverable, setAssetDeliverable] = useState<AssetDeliverable>();
  const [newMovements, setNewMovements] = useState<Movement[]>([]);
  const {
    handleContractAssetSubmit,
    removeAssetFromContract,
    updateAssetDeliverables,
    addAccessoryToContract,
    handleScheduleAllMovements,
    userCanEditContract,
    canEnterEditMode,
    revertContract,
    saveContract,
    handleEditAccessory,
    handleRemoveAccessory,
    addAssetModalMode,
    setAddAssetModalMode,
    isEditing,
    setIsEditing,
    isSubmitting,
    changeIsSubmitting,
  } = useContractBladeUtils();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const updateModalView = (
    mode: AddAssetModalMode,
    filters?: GridFilterModel,
    assetDeliverable?: AssetDeliverable
  ) => {
    setAddAssetModalMode(mode);
    setFilterModel(filters || defaultFilters);
    setAssetDeliverable(assetDeliverable);
  };

  useEffect(() => {
    if (currentContract) {
      setIsEditing(false);
    }
  }, [currentContract]);

  function statusNotAllowedToBeReverted() {
    return currentContract
      ? ["DENIED", "CANCELLED"].includes(currentContract.status)
      : true;
  }

  const handleEditModeClick = () => {
    const { allowed, message } = canEnterEditMode();
    if (allowed) {
      setIsEditing(true);
    } else {
      dispatch(simpleGlobalMessage(message));
    }
  };

  if (!currentContractId) return null;

  return (

    <Formik
      initialValues={initContract({ ...currentContract }, defaultCurrency)}
      enableReinitialize
      validationSchema={contractValidationSchema}
      onSubmit={saveContract}
    >
      {({
        resetForm,
        submitForm,
        values,
        isSubmitting: formikIsSubmitting,
      }) => {
        const closeBlade = () => {
          resetForm();
          dispatch(setCurrentContractId(undefined));
        };

        const bladeActions = () => {
          return isEditing === true
            ? [
                <IconButton
                  key="submit"
                  color="primary"
                  onClick={submitForm}
                  disabled={
                    _.isEqual(values, currentContract) || formikIsSubmitting
                  }
                >
                  {formikIsSubmitting ? (
                    <CircularProgress size="small" />
                  ) : (
                    <SaveIcon key="save" type="submit" />
                  )}
                </IconButton>,
                <IconButton
                  key="cancel"
                  onClick={() => {
                    resetForm();
                    setIsEditing(false);
                  }}
                  color="primary"
                >
                  <CancelIcon key="close" />
                </IconButton>,
              ]
            : [
                <ConfirmationModal
                  handleConfirmation={() => revertContract()}
                  message="This contract will be reverted to the previous status"
                  sx={{ display: "inline" }}
                  disabled={
                    !userCanRevertContracts || statusNotAllowedToBeReverted()
                  }
                  key="revert"
                >
                  <Tooltip
                    title={
                      statusNotAllowedToBeReverted()
                        ? `You cannot revert ${currentContract?.status} contracts`
                        : "Revert to previous status"
                    }
                  >
                    <span>
                      <IconButton
                        color="primary"
                        disabled={
                          !userCanEditContract || statusNotAllowedToBeReverted()
                        }
                      >
                        <RevertIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </ConfirmationModal>,
                <IconButton
                  color="primary"
                  key="edit"
                  onClick={handleEditModeClick}
                >
                  <EditIcon />
                </IconButton>,
                <IconButton
                  color="primary"
                  key="fullScreen"
                  onClick={() =>
                    setShowContractDetailsModal((isOpen) => !isOpen)
                  }
                >
                  <Fullscreen />
                </IconButton>,
              ];
        };

        return (
          <Form>
            <Blade
              open={Boolean(currentContractId)}
              changeOpen={closeBlade}
              backgroundColor="white"
              onTop={bladeMode === "contractViewEdit"}
              actions={bladeActions()}
            >
              <LoadingComponent isLoading={isFetching && !currentData}>
                {isSubmitting && (
                  <CircularProgress
                    color="primary"
                    sx={{
                      position: "absolute",
                      top: "calc(50% - 96px)",
                      left: "calc(50% - 48px)",
                      zIndex: 1000,
                    }}
                    size={96}
                  />
                )}
                <Box
                  sx={{
                    opacity: isSubmitting ? 0.3 : undefined,
                    pointerEvents: isSubmitting ? "none" : undefined,
                  }}
                >
                  <Box mb={2}>
                    <Typography variant="h5">
                      {currentContractClient?.companyName}
                    </Typography>
                    <Box sx={{ gridArea: "status" }}>
                      {currentContract?.contractType === "Rental"
                        ? titleCase(currentContract?.status)
                        : currentContract?.contractType === "Sale" &&
                          currentContract.status === "CONCLUDED"
                        ? "Sale Completed"
                        : titleCase(currentContract?.status)}
                    </Box>
                    <ContractStatusChangeButtons />
                  </Box>
                  <BladeTabs
                    initTab={
                      query.get("initTab") === "Assets" ? "Assets" : undefined
                    }
                    variant={isSmallScreen ? "scrollable" : "fullWidth"}
                    tabs={[
                      {
                        tabName: "Details",
                        tabComponent:
                          isEditing === true ? (
                            <ContractEdit />
                          ) : (
                            <ContractView
                              invalidContractProperties={
                                invalidContractProperties
                              }
                              changeIsSubmitting={changeIsSubmitting}
                            />
                          ),
                        tabNotificationCount: detailsTabNotifications,
                        badgeColor: "error",
                      },
                      {
                        tabComponent: (
                          <ContractAssets
                            setAddAssetMode={updateModalView}
                            changeIsSubmitting={changeIsSubmitting}
                          />
                        ),
                        tabName: "Assets",
                        tabNotificationCount: assetsTabNotifications,
                        badgeColor: currentContract?.status === "ACTIVE" ? "primary" : "error",
                      },
                      {
                        tabComponent: (
                          <ContractAccessories
                            changeIsSubmitting={changeIsSubmitting}
                          />
                        ),
                        tabName: "Accessories",
                      },
                      {
                        tabComponent: <ContractHistory />,
                        tabName: "History",
                      },
                      {
                        tabComponent: <ContractWorkOrders />,
                        tabName: "Work Orders",
                      },
                    ]}
                    isClosing={!Boolean(currentContract)}
                  />
                </Box>
              </LoadingComponent>
            </Blade>

            {addAssetModalMode !== "hidden" && (
              <AddAssetModal
                mode={addAssetModalMode}
                setMode={setAddAssetModalMode}
                filterModel={filterModel}
                handleAssetSubmit={(
                  toggleAllRowsSelected: (set?: boolean | undefined) => void,
                  selectedRows: Asset[]
                ) =>
                  handleContractAssetSubmit(
                    toggleAllRowsSelected,
                    selectedRows,
                    assetDeliverable
                  )
                }
                isSubmitting={isSubmitting}
              />
            )}
            {showContractDetailsModal && currentContract && (
              <ContractModal
                open={showContractDetailsModal}
                contract={currentContract}
                onClose={() => setShowContractDetailsModal(false)}
                addAccessory={addAccessoryToContract}
                editAccessory={handleEditAccessory}
                removeAccessory={handleRemoveAccessory}
                updateAssetDeliverables={updateAssetDeliverables}
                removeAsset={removeAssetFromContract}
                handleAssetSubmit={handleContractAssetSubmit}
                isSubmitting={isSubmitting}
                viewOnly={!userCanEditContract}
                fullScreen
                fullWidth
                disableSingleRowSelection
                addAssetModalMode={addAssetModalMode}
                setAddAssetModalMode={setAddAssetModalMode}
                additionalActions={[
                  <Button
                    onClick={() => handleScheduleAllMovements(setNewMovements)}
                    fullWidth
                    variant="outlined"
                    key="schedule-movements"
                  >
                    <Typography>Schedule All Movements</Typography>
                  </Button>,
                ]}
              />
            )}
            {Boolean(newMovements.length) && currentContract && (
              <BulkMovementCreate
                currentContract={currentContract}
                open={Boolean(newMovements.length)}
                onClose={() => setNewMovements([])}
                maxWidth="xl"
                initialMovements={newMovements}
              />
            )}
          </Form>
        );
      }}
    </Formik>

  );
};
export default ContractViewEditBlade;
