import React, { useState } from "react";
import ClientContract from "./ClientContract";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import Search from "@mui/icons-material/Search";
import { contractIsActive } from "store/slices/contractSlice";
import { selectCurrentClientId } from "store/slices/clientSlice";
import { useGetContractsByClientIdQuery } from "store/services/contract";
import LoadingComponent from "components/LoadingComponent";

interface Props {}

const ClientContracts: React.FC<Props> = () => {
  const currentClientId = useSelector(selectCurrentClientId);

  const { data: clientContracts = [], isLoading: clientContractsLoading } =
    useGetContractsByClientIdQuery(currentClientId || "", {
      skip: !currentClientId,
    });

  const [searchText, setSearchText] = useState("");
  const [showIsActive, setShowIsActive] = useState(true);

  const visibleContracts = clientContracts.filter((contract) => {
    const isActive = () => {
      return showIsActive ? contractIsActive(contract) : true;
    };

    const matchesSearch = () => {
      return Boolean(searchText)
        ? contract.projectNumber
            .toLowerCase()
            .includes(searchText.toLocaleLowerCase())
        : true;
    };

    return matchesSearch() && isActive();
  });

  function getEmptyListText() {
    switch (true) {
      case !clientContracts.length:
        return "Client does not have any contracts in Starfleet yet";
      case showIsActive && !searchText:
        return "Client has no active contracts";
      case showIsActive && Boolean(searchText):
        return "No Contracts Match Current Filters";
      default:
        return "No Contracts Match Current Filters";
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          columnGap: 1,
          positon: "sticky",
          top: 0,
        }}
      >
        <TextField
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          fullWidth
          InputProps={{
            endAdornment: <Search />,
          }}
          sx={{
            flex: 3,
          }}
        />
        <Button
          color={showIsActive ? "primary" : "inherit"}
          variant="outlined"
          onClick={() => setShowIsActive((value) => !value)}
        >
          Active
        </Button>
      </Box>
      <LoadingComponent isLoading={clientContractsLoading}>
        <List>
          {visibleContracts.length ? (
            visibleContracts.map((contract) => (
              <ClientContract key={contract._id} contract={contract} />
            ))
          ) : (
            <ListItem>
              <ListItemText>{getEmptyListText()}</ListItemText>
            </ListItem>
          )}
        </List>
      </LoadingComponent>
    </>
  );
};
export default ClientContracts;
