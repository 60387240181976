import React from 'react';
import { Icon } from '@iconify/react';
import Box, { BoxProps } from '@mui/material/Box';

interface IconifyProps extends Omit<BoxProps, 'component'> {
  icon: string; // Iconify requires an icon string
  size?: number | string; // Optional size prop to control width/height
}

const Iconify: React.FC<IconifyProps> = ({ icon, size = 24, sx, ...rest }) => {
  return (
    <Box
      component="span" // Use 'span' to ensure compatibility with all props
      sx={{
        display: 'inline-flex', // Ensures proper alignment
        alignItems: 'center',
        justifyContent: 'center',
        width: size,
        height: size,
        fontSize: size, // Required for Iconify's SVG scaling
        ...sx,
      }}
      {...rest} // Only pass Box-compatible props
    >
      <Icon icon={icon} width="100%" height="100%" />
    </Box>
  );
};

export default Iconify;
