import { IInspectionWorkOrder, initialWorkOrderState, IStandardWorkOrder, Priority, WorkOrderStatus, initPurchaseOrder } from 'store/models/WorkOrder';
import { createMongoId } from "utils/createMongoId";
import store from "store/store";
import { initialTaskState, WorkOrderTask } from "store/models/WorkOrderTask";
import { ICurrency, initCurrency } from "types/Currency";
import { IChecklistItem } from "store/models/Inspection";
import { toMMDDYYYY } from "utils/util";
import { addDays } from "date-fns";


export const initInspectionWorkOrder = (defaultCurrency: ICurrency["currency"], workOrder?: Partial<IInspectionWorkOrder>) => {
  const users = store.getState().users.users
  const loggedInUserId = store.getState().system.loggedInUserId as string;
  const loggedInUser = users[loggedInUserId]
  return {
    _id: createMongoId(),
    asset: "",
    isBlocking: false,
    dueDate: toMMDDYYYY(new Date()),
    priority: Priority.LOW,
    status: WorkOrderStatus.TO_DO,
    createdOn: toMMDDYYYY(new Date()),
    createdBy: {_id: loggedInUserId, firstName: loggedInUser?.firstName || "", lastName: loggedInUser?.lastName || ""},
    completedBy: null,
    assignedTo: null,
    photos: [],
    completedDate: null,
    number: 0,
    purchaseOrder: initPurchaseOrder({ currency: defaultCurrency }),
    attachments: [],
    ...workOrder,
  } as IInspectionWorkOrder
} 

export const initCheckListItem = (currency: ICurrency["currency"], checkList?: Partial<IChecklistItem>): IChecklistItem => {
  return {
    _id: createMongoId(),
    taskName: "",
    category: "",
    result: "Pass",
    billableToClient: true,
    estimatedHours: 0,
    estimatedMaterials: initCurrency({ currency }),
    title: "",
    priority: Priority.LOW,
    workOrderCategory: "",
    workOrderSubcategory: "",
    description: "",
    tasks: [],
    photos: [],
    workOrder: null,
    quantity: null,
    ...checkList,
    dueDate: checkList?.title && checkList.dueDate ? checkList.dueDate : toMMDDYYYY(addDays(new Date(),7)),
  }
}

export const initStandardWorkOrder = (defaultCurrency: ICurrency["currency"], workOrder?: Partial<IStandardWorkOrder>): IStandardWorkOrder => {
  const users = store.getState().users.users
  const loggedInUserId = store.getState().system.loggedInUserId as string;
  const loggedInUser = users[loggedInUserId]
  return {
    ...initialWorkOrderState,
    _id: createMongoId(),
    estimatedMaterials: initCurrency({ currency: defaultCurrency }),
    actualMaterialsToComplete: initCurrency(),
    purchaseOrder: initPurchaseOrder({ currency: defaultCurrency }),
    createdBy: {_id: loggedInUserId, firstName: loggedInUser?.firstName || "", lastName: loggedInUser?.lastName || ""},
    completedBy: null,
    ...workOrder,
  } as IStandardWorkOrder;
}

export const initWorkOrderTask = (workOrderTask?: Partial<WorkOrderTask>) => ({
  ...initialTaskState,
  ...workOrderTask
})

