import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { format } from "date-fns";
import React from "react";
import UserData from "store/models/UserData";
import { WorkOrderTask } from "store/models/WorkOrderTask";
import { dateFromMMDDYYYY, getFullName } from "utils/util";

interface Props {
    tasks: WorkOrderTask[],
    users: Record<string, UserData>
}

const WorkorderTasksTable: React.FC<Props> = ({tasks, users}) => {
    return (
        <View style={styles.table}>
          <View style={styles.tableHeader} fixed>
            <View style={styles.tableRow}>
              <View style={styles.descriptionColumn}>
                <Text style={styles.subtitle2}>Task</Text>
              </View>
              <View style={styles.completedByColumn}>
                <Text style={styles.subtitle2}>Completed By</Text>
              </View>
              <View style={styles.completedDateColumn}>
                <Text style={styles.subtitle2}>Completed Date</Text>
              </View>
            </View>
          </View>
          <View style={styles.tableBody}>
            {tasks?.map((task, index) => (
              <View key={index}>
                <View style={styles.tableRow} wrap={false}>
                    <View style={styles.descriptionColumn}>
                        <Text style={styles.body1}>
                        {task.description}
                        </Text>
                    </View>
                    <View style={styles.completedByColumn}>
                        <Text style={styles.body1}>{task.completedBy ? getFullName(users[task.completedBy]) : 'Incomplete'}</Text>
                    </View>
                    <View style={styles.completedDateColumn}>
                        <Text style={styles.body1}>{task.completedDate ? format(dateFromMMDDYYYY(task.completedDate), 'dd-MMM-yyyy') : ''}</Text>
                    </View>
                  
                
                </View>
              </View>
            ))}
          </View>
        </View>
      );
}

export default WorkorderTasksTable

const styles = StyleSheet.create({
    h3: { fontSize: 16, fontWeight: 700 },
    h4: { fontSize: 13, fontWeight: 700 },
    body1: { fontSize: 9 },
    subtitle2: {
      fontSize: 10,
      fontWeight: 600,
    },
    table: {
      width: "100%",
      fontSize: 9,
      borderCollapse: "collapse",
    },
    tableHeader: {
      borderTop: "1px solid black",
    },
    tableBody: {},
    tableRow: {
      flexDirection: "row",
      borderBottom: "1px solid black",
      alignItems: "center",
      pageBreakInside: "avoid",
    },
    descriptionColumn: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      paddingVertical: 2,
      paddingHorizontal: 2,
      borderLeft: "1px solid black",
      width: "50%",
      textAlign: 'left'
    },
    completedByColumn: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      paddingVertical: 2,
      borderLeft: "1px solid black",
      width: "25%",
      textAlign: "center",
    },
    completedDateColumn: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      paddingVertical: 2,
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      width: "25%",
      textAlign: "center",
    },
  });
  