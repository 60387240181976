import { View, Image, StyleSheet } from "@react-pdf/renderer";
import React from "react";

interface Props {
  images: string[];
  startOnNewPage?: boolean;
}

const PDFImages: React.FC<Props> = ({ images, startOnNewPage = false }) => {
  return (
    <View style={styles.imageContainer} break={startOnNewPage}>
      {images.map((img, index) => (
        <Image key={index} src={img} style={styles.image} />
      ))}
    </View>
  );
};

export default PDFImages;

const styles = StyleSheet.create({
  image: {
    maxHeight: 300,
    maxWidth: 300,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
  },
});
