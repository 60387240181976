import { getAuth } from "firebase/auth";
import { STARFLEET_STORE } from "store/store";
import { HttpSuccessResponse } from "types/HttpResponse";
import { getTimezone } from "utils/util";
import appVersion from "utils/version";

export function getResponseBody<T>(response: HttpSuccessResponse<T>) {
  return response.body;
}

export async function getRequestHeaders(
  getState: () => any
) {
  const userId = (getState() as STARFLEET_STORE).system.loggedInUserId;
  const token = await getAuth().currentUser?.getIdToken();
  const tz = getTimezone();
  const headers = new Headers();
  headers.set("Authorization", `Bearer ${token}`);
  headers.set("userid", userId as string);
  headers.set("tz", tz);
  headers.set("app-version", String(appVersion));
  headers.delete("Content-Type");
  return headers;
}

export const isFileResponse = (response: Response) => {
  let fileResponse = false;
  for (var item of response.headers.entries()) {
    if (item[0] === "fileresponse" && item[1] === "true") {
      fileResponse = true;
    }
  }
  return fileResponse;
}
