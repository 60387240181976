import  Close from "@mui/icons-material/Close";
import Check from "@mui/icons-material/Check";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Step,
  Stepper,
  StepButton,
  DialogActions,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Iconify from "components/iconify/iconify"
import AsyncButton from "components/upload/AsyncButton";
import _ from "lodash";
import { Dispatch, SetStateAction, FC, useState } from "react";
import { IInspection, IChecklistItem, IChecklistResultOption } from "store/models/Inspection";
import { useAppDispatch } from "store/store";
import { titleCase, toMMDDYYYY } from "utils/util";
import { setBladeMode, setGlobalMessage } from "store/slices/systemSlice";
import { useSelector } from "react-redux";
import MaintenanceTable from "features/maintenance/MaintenanceTable";
import WorkOrderViewEditBlade from "features/maintenance/blades/WorkOrderViewEditBlade";
import FailedListItemTable from "features/maintenance/FailedListItemTable";
import CheckListItemViewEditBlade from "features/maintenance/blades/CheckListItemViewEditBlade";
import pluralize from "pluralize";
import { selectAdditionalCheckListItems } from "store/slices/configSlice";
import { WebWOQueryResponse, useGetGeneratedWorkOrdersByInspectionIdQuery, useUpdateWorkOrderMutation } from "store/services/workOrder";
import { useGenerateInspectionWorkOrdersMutation, useUpdateCheckListItemMutation, useUpdateInspectionMutation } from "store/services/inspection";
import { WorkOrderStatus } from "store/models/WorkOrder";
import InspectionItem from "features/maintenance/modal/InspectionItem";
import { addDays } from "date-fns";

interface InspectionModalProps {
  inspection: IInspection;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  workOrder: WebWOQueryResponse;
}

const InspectionModal: FC<InspectionModalProps> = ({
  inspection,
  open,
  setOpen,
  workOrder,
}) => {
  const inspectionCategories = _.uniq(inspection.inspectionChecklist.map((item) => item.category));
  const steps = inspectionCategories.map((category) => titleCase(category));
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedListItemId, setSelectedListItemId] = useState<string>();
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<WebWOQueryResponse>();

  const dispatch = useAppDispatch()

  const [updateInspection,{ isLoading: isUpdatingInspection}] = useUpdateInspectionMutation()
  const [updateCheckListItem] = useUpdateCheckListItemMutation()
  const [updateWorkOrder, {isLoading: isUpdatingWorkOrder}] = useUpdateWorkOrderMutation()
  const [generateInspectionWorkOrders, {isLoading: isGeneratingWorkOrders}] = useGenerateInspectionWorkOrdersMutation()
  const {data: generatedWorkOrders = [], refetch} = useGetGeneratedWorkOrdersByInspectionIdQuery({inspectionId: inspection._id}, {skip: !inspection._id || !open})

  const failedCheckListItems = inspection.inspectionChecklist.filter(({ result }) => result === "Fail");
  const issuesFound = Boolean(failedCheckListItems.length)
  const selectedListItem = inspection.inspectionChecklist.find((listItem) => selectedListItemId === listItem._id)

  const additionalCheckListItems = useSelector(selectAdditionalCheckListItems);
  const [standardServiceListItem] = additionalCheckListItems;
  const standardServiceAdded = inspection.inspectionChecklist.some((listItem) => listItem.taskName === standardServiceListItem.taskName)

  const addAdditionalChecListItem = (checkListItem: IChecklistItem) => {
    const adjustedDueDate = toMMDDYYYY(addDays(new Date(), 7));
    const itemWithAdjustedDate = { ...checkListItem, dueDate: adjustedDueDate };
    updateInspection({ ...inspection, inspectionChecklist: [...inspection.inspectionChecklist, itemWithAdjustedDate] }).unwrap().then(() => {
      dispatch(setGlobalMessage({
        show: true,
        messageText: "Successfully added standard service to inspection",
        severity: "success",
      }))
    }).catch((error) => {
      dispatch(setGlobalMessage({
        show: true,
        messageText: error.data?.message || error.message || `Failed to add standard service to inspection`,
        severity: "error",
      }))
    })
  }

  if (issuesFound){
    steps.push("Repair Estimates")
  }
  if (generatedWorkOrders.length) {
    steps.push("Work Orders")
  }
  const isLastStep = Boolean(currentStep === steps.length -1)

  const closeModal = () => setOpen(false);

  const handleItemToggle = (
    item: IChecklistItem,
    result: IChecklistResultOption
  ) => {
    updateCheckListItem({inspectionId: inspection._id, checklistItem: { ...item, result }});
  };

  const getCurrentStep = () => {
    if (steps[currentStep] === "Repair Estimates") {
      return (
        <Box
          key={currentStep}
        >
          <Typography variant="h4" sx={{ mb: 2 }}>
            Failed Check List Items
          </Typography>
          <FailedListItemTable 
            checkListItems={failedCheckListItems}
            onRowClick={(checkListItem) => setSelectedListItemId(checkListItem._id)}
            generatedWorkOrders={generatedWorkOrders}
          />
          {selectedListItem && (
            <CheckListItemViewEditBlade 
              open={Boolean(selectedListItem)}
              checkListItem={selectedListItem}
              inspection={inspection}
              onClose={() => setSelectedListItemId(undefined)}
            />
          )}
        </Box>
      );
    } else if (steps[currentStep] === "Work Orders") {
      return (
        <Box key={currentStep}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Inspection Generated Work Orders
          </Typography>
          <MaintenanceTable
            workOrders={generatedWorkOrders}
            onRowClick={(workOrder) => {
              dispatch(setBladeMode("workOrderViewEdit"));
              setSelectedWorkOrder(workOrder);
            }}
            tableId="inspectionWorkOrdersTable"
          />
          {selectedWorkOrder && (
            <WorkOrderViewEditBlade
              open={Boolean(selectedWorkOrder)}
              workOrder={selectedWorkOrder}
              onClose={() => setSelectedWorkOrder(undefined)}
            />
          )}
        </Box>
      );
    } else {
      const category = inspectionCategories[currentStep];
      return (
        <Box sx={{ width: "100%", height: "100%" }} key={currentStep}>
          <Typography variant="h5" sx={{ my: 2 }}>
            {category}
          </Typography>
          <Box
            key={currentStep}
            sx={{
              display: "grid",
              py: 5,
              gridTemplateColumns: {
                lg: "auto auto auto",
                md: "auto auto",
                xs: "auto",
              },
              gap: 2,
            }}
          >
            {inspection.inspectionChecklist
              .filter((inspection) => inspection.category === category)
              .map((item, index) => (
                <InspectionItem
                  key={`checkListItem - ${item.category} - ${index}`}
                  item={item}
                  handleItemToggle={handleItemToggle}
                  setSelectedListItemId={setSelectedListItemId}
                />
              ))}
          </Box>
          {selectedListItem && (
            <CheckListItemViewEditBlade 
              open={Boolean(selectedListItem)}
              checkListItem={selectedListItem}
              inspection={inspection}
              onClose={() => setSelectedListItemId(undefined)}
            />
          )}
        </Box>
      );
    }
  }

  const completeInspection = async ()  => {
    updateWorkOrder({...workOrder, status: WorkOrderStatus.COMPLETE}).unwrap().then((updatedWorkOrder) => {
      dispatch(setGlobalMessage({
        show: true,
        messageText: "Successfully completed inspection",
        severity: "success",
      }))
    }).catch((error) => {
      dispatch(setGlobalMessage({
        show: true,
        messageText: error.data?.message || error.message || `Failed to complete inspection`,
        severity: "error",
      }))
    })
  }

  const workOrdersCanBeGenerated = () => {
    const allFailedListItemsAreValid = failedCheckListItems.every(hasRequiredStandardWorkOrderProps);
    return allFailedListItemsAreValid;

    function hasRequiredStandardWorkOrderProps(checkListItem: IChecklistItem) {
      return Boolean(
        checkListItem.estimatedMaterials.amount >= 0 &&
        checkListItem.estimatedHours >= 0 &&
        checkListItem.dueDate &&
        checkListItem.title &&
        checkListItem.workOrderCategory &&
        checkListItem.workOrderSubcategory && 
        checkListItem.priority 
      );
    }
  }

  const generateWorkOrders = () => {
    if(!workOrdersCanBeGenerated()){
      dispatch(setGlobalMessage({
        show: true,
        messageText: "1 or more Check list Items are missing required fields",
        severity: "warning",
      }));
      return;
    }

    generateInspectionWorkOrders({inspectionId: inspection._id}).unwrap().then(({workOrders}) => {
      refetch()
      dispatch(setGlobalMessage({
        show: true,
        messageText: `Successfully generated ${workOrders.length} Work ${pluralize("Order", workOrders.length)}`,
        severity: "success",
      }));
    })
  }
  

  function showCompleteButton () {
    return steps[currentStep] === "Repair Estimates" && !generatedWorkOrders.length || isLastStep && steps[currentStep] !== "Work Orders";
  }

  return (
    <Dialog open={open} onClose={closeModal} fullScreen>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h3">Inspection Work Order</Typography>
        <Box>
          <Button
            onClick={() => addAdditionalChecListItem(standardServiceListItem)}
            variant="contained"
            color="primary"
            sx={{ mr: 3, width: 200 }}
            disabled={isUpdatingInspection || standardServiceAdded}
          >
            {isUpdatingInspection ? <CircularProgress size={24} /> : "Add Standard Service"}
          </Button>
          {workOrder.status === "COMPLETE" && (
            <Button
              onClick={generateWorkOrders}
              variant="contained"
              color="primary"
              sx={{ mr: 3, width: 200 }}
              disabled={isGeneratingWorkOrders}
            >
              {isGeneratingWorkOrders ? <CircularProgress size={24} /> : "Generate Work Orders"}
            </Button>
          )}
          <IconButton onClick={closeModal}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        {getCurrentStep()}
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
          <Button
            variant="outlined"
            size="small"
            color="inherit"
            onClick={() => setCurrentStep((step) => step - 1)}
            startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
            disabled={currentStep === 0}
            sx={{ minWidth: "160px" }}
          >
            Back
          </Button>
          <Stepper
            nonLinear
            activeStep={currentStep}
            sx={{ width: "80%", mx: 3 }}
          >
            {steps.map((label, index) => (
              <Step key={label} completed={currentStep === steps.length}>
                <StepButton
                  color="inherit"
                  onClick={() => setCurrentStep(index)}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          {
            showCompleteButton() ? (
              <AsyncButton
                variant="outlined"
                size="small"
                onClick={completeInspection}
                startIcon={<Check />}
                sx={{ minWidth: "160px" }}
                isLoading={isUpdatingWorkOrder}
                disabled={isUpdatingWorkOrder || Boolean(workOrder.completedDate)}
              >
                Complete
              </AsyncButton>
            ) : (
              isLastStep ? (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={closeModal}
                  startIcon={<Check />}
                  sx={{ minWidth: "160px" }}
                >
                  Done
                </Button>
                
              ) : (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setCurrentStep((step) => step + 1)}
                  startIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
                  sx={{ minWidth: "160px" }}
                >
                  Next
                </Button>
              )
            )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default InspectionModal;
