import { View, Text, StyleSheet } from '@react-pdf/renderer';
import React from 'react'

interface Props {}

const PDFFooter: React.FC<Props> = () => {
  return (
    <View style={styles.footer} fixed>
      <Text
        render={({ pageNumber, totalPages }) =>
          `pg ${pageNumber} / ${totalPages}`
        }
        style={{ textAlign: "center" }}
      />
    </View>
  );
};

export default PDFFooter

const styles = StyleSheet.create({
    footer: {
      display: "flex", 
      flex: 1,
      justifyContent: "flex-end",
      margin: 10,
      padding: 10,
      width: "100%",
    },
  });