import EventNote from "@mui/icons-material/EventNote";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  IconButton,
  Typography,
  AccordionDetails,
  Tooltip,
  Box,
} from "@mui/material";
import React, { useState, MouseEvent, Dispatch, SetStateAction, useMemo } from "react";
import Movement, { MovementStatus, MovementType, determineDefaultDateOfMove, initMovement } from "store/models/Movement";
import ContractMovement from "./ContractAccessoryMovement";
import IAccessory from "store/models/Accessory";
import { useSelector } from "react-redux";
import { selectCurrentContractId } from "store/slices/contractSlice";
import { determineDefaultMovementStatus } from "utils/util";
import BulkMovementCreate from "features/contracts/BulkMovementCreate";
import UserData from "store/models/UserData";
import { selectLoggedInUser, setGlobalMessage } from "store/slices/systemSlice";
import { useAppDispatch } from "store/store";
import { useGetContractByIdQuery } from "store/services/contract";


interface Props {
  accessory: IAccessory
  changeIsSubmitting: Dispatch<SetStateAction<boolean>>
}

const MovementAccordion: React.FC<Props> = ({ accessory, changeIsSubmitting }) => {
  const currentContractId= useSelector(selectCurrentContractId);
  const {data: currentContract} = useGetContractByIdQuery(currentContractId || "", {skip: !currentContractId});
  const [showScheduleMovement, setShowScheduleMovement] = useState(false);
  const [newMovements, setNewMovements] = useState<Movement[]>([]);
  const loggedInUser = useSelector(selectLoggedInUser);
  const dispatch = useAppDispatch()
  const movements = useMemo(
    () =>
      currentContract?.movements.filter((movement) => {
          return movement.accessory === accessory._id;
      }) as Movement[],
    [currentContract?.movements]
  );


  const handleCloseMovementCreateModal = () => {
    setShowScheduleMovement(false);
    setNewMovements([]);
  }

  const defaultMovementStatus = determineDefaultMovementStatus(currentContract)
  if (!currentContract) return null;
  const hasPendingMovements = movements.some(movement => movement.status === MovementStatus.dispatched || movement.status === MovementStatus.scheduled)
  
  const scheduleNewMovement = (event: MouseEvent<HTMLButtonElement>) => {
    const accessoryHasBranch = Boolean(accessory.branch)
    if (!accessoryHasBranch) {
      dispatch(setGlobalMessage({messageText: "Accessories must be assigned to a branch before a movement can be created", show: true, severity: "warning"}))
      event.stopPropagation()
      return;
    }
    const movementToSchedule = {
      nonAsset: null,
      dateOfMove: determineDefaultDateOfMove(
        defaultMovementStatus,
        accessory,
        currentContract
      ),
      type: hasPendingMovements ? MovementType.relocate : defaultMovementStatus,
      status: MovementStatus.scheduled,
      contract: currentContract?._id,
      createdBy: (loggedInUser as UserData)._id,
      dateCompleted: null,
    } as Movement;
      movementToSchedule.accessory = accessory._id
      movementToSchedule.asset = null;


    event.stopPropagation();
    setShowScheduleMovement(true);
    setNewMovements([
      initMovement(movementToSchedule),
    ])
  }
  

  return (
    <Accordion sx={{":before": {display: "none"}, px: 0}}>
      <AccordionSummary
        expandIcon={
          <IconButton>
            <ExpandMore />
          </IconButton>
        }
        sx={{width: "100%"}}
      >
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
          <Tooltip title="Schedule Movement">
            <span>
              <IconButton onClick={(event) => scheduleNewMovement(event)}>
                <EventNote />
              </IconButton>
            </span>
          </Tooltip>
          <Typography color="primary">
            Movements
            ({movements.length})
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            width: "100%",
            px: 0
          }}
        >
          {movements.length
            ? movements.map((movement, index) => {
                return (
                  <ContractMovement
                    movement={movement}
                    disabled={false}
                    key={index}
                    changeIsSubmitting={changeIsSubmitting}
                  />
                );
              })
            : "No Movements"}
        </Box>
      </AccordionDetails>
      {showScheduleMovement && (
        <BulkMovementCreate
          currentContract={currentContract}
          open={showScheduleMovement}
          onClose={handleCloseMovementCreateModal}
          maxWidth="xl"
          initialMovements={newMovements}
        />
      )}
    </Accordion>
  );
};

export default MovementAccordion;
