import analyticsApi from "./analytics";
import branchesApi from "./branch";
import yardsApi from "./yard";
import usersApi from "./user";
import attachmentsApi from "./attachment";
import workOrderPhotosApi from "./workOrderPhoto";
import assetActionRecordApi from './assetActionRecord';
import clientsApi from './client';
import leadApi from './lead';
import configApi from './configs';
import userPreferenceApi from "./userPreference";

const api = {
  analytics: analyticsApi,
  branches: branchesApi,
  yards: yardsApi,
  users: usersApi,
  attachments: attachmentsApi,
  workOrderPhotos: workOrderPhotosApi,
  assetActionRecord: assetActionRecordApi,
  clients: clientsApi,
  lead: leadApi,
  config: configApi,
  userPreference: userPreferenceApi,
};

export default api;