import React from "react";
import { Contract, ContractStatus } from "store/models/Contract";
import { useSelector } from "react-redux";
import { selectLoggedInUser, setGlobalMessage, simpleGlobalMessage } from "store/slices/systemSlice";
import usePermissions, {
  ActionType,
  FeatureType,
} from "hooks/usePermissions";
import { Box, Button, ButtonProps } from "@mui/material";
import ConfirmationModal from "components/ConfirmationModal";
import { selectCurrentContractId } from "store/slices/contractSlice";
import { useAppDispatch } from 'store/store';
import { useAdvanceContractStatusMutation, useCancelContractMutation, useGetContractByIdQuery } from "store/services/contract";

type ButtonAction = {
  label: string;
  contractStatus: Contract["status"];
  color?: ButtonProps["color"];
  onClick: () => void;
};

type ContactStatusOptions = {
  [status in ContractStatus]: {
    primaryAction?: ButtonAction
    alternateAction?: ButtonAction
    
  };
};

interface Props {}

const ContractStatusChangeButtons: React.FC<Props> = () => {
  const currentContractId = useSelector(selectCurrentContractId);
  const {data: currentContract} = useGetContractByIdQuery(currentContractId || "", { skip: !currentContractId });
  const dispatch = useAppDispatch();
  const loggedInUser = useSelector(selectLoggedInUser);
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const userCanEditContracts = checkContractPermissions(ActionType.UPDATE);
  const [advanceContractStatusMutation] = useAdvanceContractStatusMutation();
  const [cancelContractMutation] = useCancelContractMutation();

  if (!currentContract) return null
  const advanceContractStatus = () => {
    currentContract &&
      advanceContractStatusMutation({contractId: currentContract._id, currentStatus: currentContract.status}).unwrap()
      .then(({ contract, assets }) => {
        dispatch(setGlobalMessage({messageText: `Contract successfully advanced to ${contract.status} `, severity: "success", show: true}));
      })
      .catch((error) => {
        dispatch(setGlobalMessage({messageText: error.data.message || 'Failed to advance contract status', severity: "error", show: true}));
      });
  };

const cancelContract = (action: "CANCEL" | "DENY") => {
  currentContract &&
    cancelContractMutation({contractId: currentContract._id, action}).unwrap()
    .then(({ contract, assets }) => {
      dispatch(setGlobalMessage({messageText: `Contract successfully ${action === 'CANCEL' ? 'cancelled' : 'denied'}.`, severity: "success", show: true}));
    })
    .catch((error) => {
      dispatch(simpleGlobalMessage(error.data.message || 'Failed to cancel contract', "error"));
    });
};
const contractStatusOptionMap: ContactStatusOptions = {
  "AWAITING APPROVAL": {
    primaryAction: {
      label: "Approve",
      contractStatus: "AWAITING CONFIRMATION",
      onClick: advanceContractStatus,
    },
    alternateAction: userCanEditContracts
      ? {
          label: "Deny",
          color: "error",
          contractStatus: "DENIED",
          onClick: () => cancelContract("DENY"),
        }
      : {
          label: "Cancel",
          color: "error",
          contractStatus: "CANCELLED",
          onClick: () => cancelContract("CANCEL"),
        },
  },

  "AWAITING CONFIRMATION": {
    primaryAction: {
      label: "Pending Delivery",
      contractStatus: "PENDING DELIVERY",
      onClick: advanceContractStatus,
    },
    alternateAction: {
      label: "Cancel",
      color: "error",
      contractStatus: "CANCELLED",
      onClick:  () => cancelContract("CANCEL"),
    },
  },
  "PENDING DELIVERY": {
    primaryAction: {
      label: currentContract.contractType === "Rental" ? "Activate" : "Confirm Delivery",
      contractStatus: "ACTIVE",
      onClick: advanceContractStatus,
    },
    alternateAction: {
      label: "Cancel",
      color: "error",
      contractStatus: "CANCELLED",
      onClick:  () => cancelContract("CANCEL"),
    },
  },
  ACTIVE: {
    primaryAction: {
      label: currentContract.contractType === "Rental" ? "Conclude" : "Complete Sale",
      contractStatus: "CONCLUDED",
      onClick: advanceContractStatus,
    },
  },
  DENIED: {},
  CANCELLED: {},
  CONCLUDED: {},
};

  function disableButton(newStatus: Contract["status"]) {
    if (newStatus === "CANCELLED") {
      const userCanEditContracts = checkContractPermissions(ActionType.UPDATE);
      const userCanCancelOwnContract =
        userTryingToCancelTheirOwnContract() &&
        checkContractPermissions(ActionType.LIMITED_UPDATE);

      return !(userCanCancelOwnContract || userCanEditContracts);
    } else {
      const userCanEditContracts = checkContractPermissions(ActionType.UPDATE);

      return !userCanEditContracts;
    }
  }

  function userTryingToCancelTheirOwnContract() {
    return loggedInUser?._id === currentContract?.createdBy;
  }

  const { primaryAction, alternateAction } =
  contractStatusOptionMap[currentContract?.status];


  return (
    <Box
      sx={{
        display: "grid",
        columnGap: 1,
        gridAutoFlow: "column",
        gridTemplateColumns: "repeat(1fr)",
      }}
    >
      {primaryAction && (
        <Button
          disabled={disableButton(primaryAction.contractStatus)}
          onClick={primaryAction.onClick}
          variant="outlined"
          fullWidth
          sx={{
            whiteSpace: "nowrap",
            mt: 2,
          }}
          color={primaryAction.color}
        >
          {primaryAction.label}
        </Button>
      )}
      {alternateAction && (
        <ConfirmationModal
          handleConfirmation={alternateAction.onClick}
          message={`Are you sure you want to ${alternateAction.label.toLowerCase()} this contract?`}
          disabled={disableButton(alternateAction.contractStatus)}
        >
          <Button
            disabled={disableButton(alternateAction.contractStatus)}
            variant="contained"
            fullWidth
            sx={{
              whiteSpace: "nowrap",
              mt: 2,
            }}
            color={alternateAction.color}
          >
            {alternateAction.label}
          </Button>
        </ConfirmationModal>
      )}
    </Box>
  );
};
export default ContractStatusChangeButtons;
