import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "store/api";
import {
  FeatureType,
  ActionType,
} from "hooks/usePermissions";
import { STARFLEET_STORE } from "store/store";
import { Currency } from "dinero.js";
import UserData from "store/models/UserData";
import { IInspection } from "store/models/Inspection";

export type NotificationIncludeOption = "all" | "mine" | "typeOf" | "none";

export type NotificationConfig = {
  include: NotificationIncludeOption; 
  sendEmail: boolean;
  sendSMS: boolean;
  includeTypes?: string[];  
}

export enum NotificationFeatures {
  CONTRACT_CREATE = "CONTRACT_CREATE",
  CONTRACT_ENDING = "CONTRACT_ENDING",
  CONTRACT_UPDATE = "CONTRACT_UPDATE",
  RESERVATION_EXPIRING = "RESERVATION_EXPIRING",
}

export type DefaultNotificationSetting = {
  [key in NotificationFeatures]: NotificationConfig & { options: Array<NotificationIncludeOption>, helperText?: string};
};

export interface IUserPreference {
  _id: string;
  name: "Notification Settings";
  userId: string;
  preferences: {
    [key in NotificationFeatures]: NotificationConfig;
  };
}

export interface Config<settingsType = any> {
  _id: string;
  name: keyof Omit<ConfigStore, "configLoading">;
  settings: settingsType;
}

export type IPermissions = {
   [role: string]: {
    [key in FeatureType]: {
      [key in ActionType]: boolean;
    };
  };
};

export interface ICondition {
  conditionString: string;
  number: number;
}

export interface ICountry {
  name: string;
  currency: Currency;
}

export interface CheckListItemConfig {
  category: string;
  taskName: Currency;
}

export interface ConfigStore {
  configLoading: boolean;
  permissions: Config<IPermissions> | undefined;
  categories: Config<string[]>;
  sizeCodes: Config<string[]>;
  airConditionTypes: Config<string[]>;
  conditions: Config<ICondition[]>;
  branding: Config<string[]>;
  buildingCodes: Config<string[]>;
  cladding: Config<string[]>;
  electrical: Config<string[]>;
  floorStyles: Config<string[]>;
  fuelTypes: Config<string[]>;
  statuses: Config<string[]>;
  structuralLimits: Config<string[]>;
  subCategories: Config<string[]>;
  washRoomCounts: Config<number[]>;
  partitions: Config<number[]>;
  countries: Config<{[name: string]: {name: string, currency: Currency}}>;
  unitsOfMeasure: Config<string[]>;
  accessories: Config<string[]>;
  maintenanceCategories: Config<{[category: string]: string[]}>
  additionalCheckListItems: Config<IInspection["inspectionChecklist"]>
  defaultNotificationSettings: Config<{ [role: string]: DefaultNotificationSetting}> | undefined;
}

const initConfig = <T = string>(name: string, settings: Array<T> = []): Config => ({
  _id: "",
  name: name as keyof Omit<ConfigStore, "configLoading">,
  settings,
})

const initialState: ConfigStore = {
  configLoading: false,
  permissions: undefined,
  categories: initConfig("categories"),
  sizeCodes: initConfig("sizeCodes"),
  airConditionTypes: initConfig("airConditionTypes"),
  conditions: initConfig("conditions"),
  branding: initConfig("branding"),
  buildingCodes: initConfig("buildingCodes"),
  cladding: initConfig("cladding"),
  electrical: initConfig("electrical"),
  floorStyles: initConfig("floorStyles"),
  fuelTypes: initConfig("fuelTypes"),
  statuses: initConfig("statuses"),
  structuralLimits: initConfig("structuralLimits"),
  subCategories: initConfig("subCategories"),
  washRoomCounts: initConfig<number>("washRoomCounts"),
  partitions: initConfig<number>("partitions"),
  countries: initConfig("countries"),
  unitsOfMeasure: initConfig("unitsOfMeasure"),
  accessories: initConfig("accessories"),
  maintenanceCategories: {_id: "", name: "maintenanceCategories", settings: {}},
  additionalCheckListItems: {_id: "", name: "additionalCheckListItems", settings: []},
  defaultNotificationSettings: undefined,
};

export const updateConfig = (config: Config) => {
  return (dispatch: Function) => {
    api.config.updateOne(config, {
      onData: (data) => {
        dispatch(updateOneConfig(data));
      },
    });
  };
};


const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    updateOneConfig(state, action: PayloadAction<Config>) {
      state[action.payload.name] = action.payload;
    },
    setConfigs(state, action: PayloadAction<Config[]>) {
      for (var i = 0; i < action.payload.length; i++) {
        state[action.payload[i].name] = action.payload[i];
      }
      state.configLoading = false;
    },
  },
});

export const fetchConfigs = () => {
  return (dispatch: Function) => {
    api.config.getAll({
      onData: (data) => {
        dispatch(setConfigs(data));
      },
    });
  };
};

export const { updateOneConfig, setConfigs } = configSlice.actions;

export const selectPermissions = (state: STARFLEET_STORE) => state.config.permissions?.settings
export const selectMaintenanceCategories = (state: STARFLEET_STORE) => state.config.maintenanceCategories.settings
export const selectAdditionalCheckListItems = (state: STARFLEET_STORE) => state.config.additionalCheckListItems.settings
export const selectCategories = (state: STARFLEET_STORE) => state.config.categories.settings;
export const selectSizeCodes = (state: STARFLEET_STORE) => state.config.sizeCodes.settings;
export const selectSizeCodesState = (state: STARFLEET_STORE) => state.config.sizeCodes  as Config<string[]>;
export const selectAirConditionTypes = (state: STARFLEET_STORE) => state.config.airConditionTypes.settings;
export const selectConditions = (state: STARFLEET_STORE) => state.config.conditions.settings;
export const selectBranding = (state: STARFLEET_STORE) => state.config.branding.settings;
export const selectBuildingCodes = (state: STARFLEET_STORE) => state.config.buildingCodes.settings;
export const selectCladding = (state: STARFLEET_STORE) => state.config.cladding.settings;
export const selectElectrical = (state: STARFLEET_STORE) => state.config.electrical.settings;
export const selectFloorSytles = (state: STARFLEET_STORE) => state.config.floorStyles.settings;
export const selectFuelTypes = (state: STARFLEET_STORE) => state.config.fuelTypes.settings;
export const selectStatuses = (state: STARFLEET_STORE) => state.config.statuses.settings;
export const selectStructuralLimits = (state: STARFLEET_STORE) => state.config.structuralLimits.settings;
export const selectSubCategories = (state: STARFLEET_STORE) => state.config.subCategories.settings;
export const selectWashRoomCounts = (state: STARFLEET_STORE) => state.config.washRoomCounts.settings;
export const selectPartitions = (state: STARFLEET_STORE) => state.config.partitions.settings;
export const selectPermissionsState = (state: STARFLEET_STORE) => state.config.permissions  as Config<IPermissions>;
export const selectCountries = (state: STARFLEET_STORE) => state.config.countries;
export const selectUnitsOfMeasure = (state: STARFLEET_STORE) => state.config.unitsOfMeasure;
export const selectAccessories = (state: STARFLEET_STORE) => state.config.accessories;
export const selectCurrencies = (state: STARFLEET_STORE) => Object.values(state.config.countries.settings).map((country) => country.currency);
export const selectCurrencyConfig = (state: STARFLEET_STORE) => state.config.countries.settings;
export const selectDefaultUserCurrency = (user?: UserData) => (state: STARFLEET_STORE) => user ? state.config.countries.settings[user.countries[0]]?.currency : "CAD" as Currency;
export const selectDefaultUserCountry = (state: STARFLEET_STORE) => state.users.users[`${state.system.loggedInUserId}`].countries[0];


export const selectUserRoles = (state: STARFLEET_STORE) =>
  Boolean(state.config.permissions)
    ? Object.keys((state.config.permissions as Config<IPermissions>).settings)
    : [];

export const selectDefaultUserNotificationSettings = (state: STARFLEET_STORE): DefaultNotificationSetting => {
  const { role } = state.users.users[String(state.system.loggedInUserId)]
  const { defaultNotificationSettings } = state.config
  return  defaultNotificationSettings?.settings[role] as DefaultNotificationSetting;
}

export default configSlice.reducer;
