import { Button, ButtonProps, CircularProgress } from "@mui/material";
import React, { FC, MouseEvent, useState } from "react";

interface Props extends ButtonProps {
  onClick: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => Promise<any>;
  isLoading?: boolean
}

const AsyncButton: FC<Props & { onClick: ButtonProps["onClick"] }> = ({
  onClick,
  children,
  disabled,
  startIcon,
  isLoading = false,
  ...props
}) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const handleClick = async (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    isLoading ?? setSubmitting(true);
    const onClickPromise = onClick(event);
    await onClickPromise.finally(() => setSubmitting(false));
  };

  return (
    <Button
      {...props}
      onClick={handleClick}
      disabled={isSubmitting || disabled}
      startIcon={startIcon}
    >
      {isLoading || isSubmitting ? <CircularProgress color={props.variant === "contained" ? "inherit" : "primary"} size={20} /> : children}
    </Button>
  );
};

export default AsyncButton;