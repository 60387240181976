import { FC } from "react";
import { Page, View, Text, Document, StyleSheet, Font,  } from '@react-pdf/renderer';
import { getFullName } from "utils/util";
import { Branch } from "store/models/Branch";
import { Asset } from "store/models/Asset";
import UserData from "store/models/UserData";
import PDFHeader from "../../../components/pdf/PDFHeader";
import { AssetWorkOrder } from "store/services/workOrder";
import InspectionChecklistItemsTable from "./InspectionChecklistItemsTable";
import { WorkOrderStatus } from "store/models/WorkOrder";
import WorkorderTasksTable from "./WorkorderTasksTable";
import PDFFooter from "components/pdf/PDFFooter";


Font.register({
  family: 'Roboto',
  fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf' }],
});


interface Props {
  workOrders: AssetWorkOrder[];
  users: Record<string, UserData>;
  asset: Asset;
  branch: Branch;

}

const AssetWorkOrderSummaryPDF: FC<Props> = ({ users, workOrders, asset, branch }) => {
  const assetInfo = {
    "Serial Number": asset?.serialNumber,
    "Asset Number": asset?.assetNumber,
    "Size Code": asset?.sizeCode,
    "Asset Category": asset?.category,
    "Asset Sub Category": asset?.subCategory,
    Branch: branch?.name,
  };
  const isUSA = branch?.country === "USA";

  return (
    <Document style={{ height: "100%", width: "100%" }}>
      <Page size="A4" style={styles.page}>
        <PDFHeader isUSA={isUSA} branch={branch} />
        <View style={{ marginBottom: 20 }}>
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {Object.entries(assetInfo).map(([label, value]) => (
              <InfoCell key={label} label={label} value={value} />
            ))}
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableRow}>
              <View style={styles.numberColumn}>
                <Text style={styles.subtitle2}>#</Text>
              </View>
              <View style={styles.titleColumn}>
                <Text style={styles.subtitle2}>Title</Text>
              </View>
              <View style={styles.descriptionColumn}>
                <Text style={styles.subtitle2}>Description</Text>
              </View>
              <View style={styles.hoursColumn}>
                <Text style={styles.subtitle2}>Hrs</Text>
              </View>
              <View style={styles.statusColumn}>
                <Text style={styles.subtitle2}>Status</Text>
              </View>
              <View style={styles.assignedColumn}>
                <Text style={styles.subtitle2}>Assigned</Text>
              </View>
              <View style={styles.dueDateColumn}>
                <Text style={styles.subtitle2}>Due</Text>
              </View>
              <View style={styles.priorityColumn}>
                <Text style={styles.subtitle2}>Priority</Text>
              </View>
              <View style={styles.categoryColumn}>
                <Text style={styles.subtitle2}>Category</Text>
              </View>
            </View>
          </View>
          <View style={styles.tableBody}>
            {workOrders.map((workOrder) => (
              <WorkOrderRow workOrder={workOrder} users={users} />
            ))}
          </View>
        </View>
        {workOrders.map((workOrder) =>
          workOrder.inspection ? (
            <InspectionPage inspectionWorkOrder={workOrder} users={users} />
          ) : workOrder.tasks?.length ? (
            <WorkOrderPage workOrder={workOrder} users={users} />
          ) : null
        )}
        <PDFFooter />
      </Page>
    </Document>
  );
};


function InfoCell({
  label,
  value,
}: {
  label: string;
  value: string | number | undefined;
}) {
  return (
    <View
      style={styles.infoCell}
    >
      <Text style={{ fontWeight: 800, marginLeft: 4 }}>
        {label}:
      </Text>
      <Text wrap style={{marginLeft: 4}}>{value}</Text>
    </View>
  );
}

function WorkOrderRow({
  workOrder,
  users,
}: {
  workOrder: AssetWorkOrder;
  users: Record<string, UserData>;
}) {
  return (
    <View style={styles.tableRow}>
      <View style={styles.numberColumn}>
        <Text style={styles.body1}>{workOrder.number}</Text>
      </View>
      <View style={styles.titleColumn}>
        <Text style={styles.body1}>
          {workOrder.title || workOrder.inspectionType}
        </Text>
      </View>
      <View style={styles.descriptionColumn}>
        <Text style={styles.body1}>
          {workOrder.description || "ASL Contract Inspection"}
        </Text>
      </View>
      <View style={styles.hoursColumn}>
        <Text style={styles.body1}>{workOrder.status === WorkOrderStatus.COMPLETE ? workOrder.actualHoursToComplete : workOrder.estimatedHours}</Text>
      </View>
      <View style={styles.statusColumn}>
        <Text style={styles.body1}>{workOrder.status}</Text>
      </View>
      <View style={styles.assignedColumn}>
        <Text style={styles.body1}>
          {workOrder.assignedTo ? getFullName(users[workOrder.assignedTo]) : 'Unassigned'}
        </Text>
      </View>
      <View style={styles.dueDateColumn}>
        <Text style={styles.body1}>{workOrder.dueDate}</Text>
      </View>
      <View style={styles.priorityColumn}>
        <Text style={styles.body1}>{workOrder.priority}</Text>
      </View>
      <View style={styles.categoryColumn}>
        <Text style={styles.body1}>{workOrder.category}</Text>
      </View>
    </View>
  );
}

function WorkOrderPage({workOrder, users}: {workOrder: AssetWorkOrder, users: Record<string, UserData>}) {
  return (
      <View break>
        <View style={styles.tableTitle} fixed>
          <Text style={styles.h3}>#{workOrder.number} - {workOrder.title}</Text>
        </View>
        <WorkorderTasksTable tasks={workOrder.tasks || []} users={users} />
      </View>
  );

}

function InspectionPage({inspectionWorkOrder, users}: {inspectionWorkOrder: AssetWorkOrder, users: Record<string, UserData>}) {
  return (
      <View break>
        <View style={styles.tableTitle} fixed>
          <Text style={styles.h3}>
            #{inspectionWorkOrder.number} - {inspectionWorkOrder.inspectionType}
          </Text>
        </View>
        <InspectionChecklistItemsTable
          items={inspectionWorkOrder.inspection.inspectionChecklist}
          workOrder={inspectionWorkOrder}
        />
      </View>
  );
}



export default AssetWorkOrderSummaryPDF;

const styles = StyleSheet.create({
  h3: { fontSize: 16, fontWeight: 700 },
  h4: { fontSize: 12, fontWeight: 700 },
  body1: { fontSize: 8 },
  subtitle2: { fontSize: 10, fontWeight: 600 },
  alignRight: { textAlign: "right" },
  alignCenter: { textAlign: "center" },
  alignLeft: { textAlign: "left" },
  page: {
    padding: "40px 24px 0 24px",
    fontSize: 9,
    lineHeight: 1.6,
    backgroundColor: "#fff",
    fontFamily: "Roboto",
    flex: 1,
    height: '100%',
  },
  footer: {
    display: "flex", 
    flex: 1,
    justifyContent: "flex-end",
    margin: 10,
    padding: 10,
    width: "100%",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  noBorder: { paddingTop: 8, paddingBottom: 0, borderBottomWidth: 0 },
  table: {
    width: "100%",
    fontSize: 9,
    marginVertical: 4,
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    borderTop: "1px solid black",
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: "#80808080",
  },
  tableBody: {
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "black",
    alignItems: "center",
    pageBreakInside: "avoid",
  },
  tableTitle: {
    width: "100%",
    fontSize: 12,
    padding: "6px 12px",
    paddingBottom: 0,
    borderLeft: "1px solid black",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
  },
  numberColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: 4,
    width: "4%",
    borderRight: "1px solid black",
    textAlign: "center",
    fontSize: 8
  },
  titleColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: 4,
    paddingHorizontal: 2,
    width: "17%",
    borderRight: "1px solid black",
    textAlign: "left"
  },
  descriptionColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: 4,
    paddingHorizontal: 2,
    width: "20%",
  },
  hoursColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: 4,
    paddingHorizontal: 2,
    borderLeft: "1px solid black",
    width: "5%",
    textAlign: "center"
  },
  statusColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: 4,
    paddingHorizontal: 2,
    borderLeft: "1px solid black",
    width: "10%",
    textAlign: "center"
  },
  assignedColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: 4,
    paddingHorizontal: 2,
    borderLeft: "1px solid black",
    width: "10%",
    textAlign: "center"
  },
  dueDateColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: 4,
    paddingHorizontal: 2,
    borderLeft: "1px solid black",
    width: "10%",
    textAlign: "center"
  },
  priorityColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: 4,
    borderLeft: "1px solid black",
    width: "8%",
    textAlign: "center"
  },
  categoryColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: 4,
    borderLeft: "1px solid black",
    width: "12%",
    textAlign: "center"
  },
  infoCell: {
    flexDirection: "row",
    fontSize: 10,
    alignItems: "center",
    minHeight: 20,
    paddingTop: 3,
    width: "50%",
    fontFamily: 'Roboto',
    flexWrap: "wrap",
    border: '0.5px solid black'
  },
  completeColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: 4,
    borderLeft: "1px solid black",
    width: "15%",
    textAlign: "center"
  },
  completedByColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: 4,
    borderLeft: "1px solid black",
    width: "20%",
    textAlign: "center"
  },
  underline: {
    borderBottom: "1px solid black",
  },
  checklistCategory: {
    width: "15%",
    fontFamily: 'Roboto',
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 3
  },
  checklistTask: {
    width: "35%",
    textAlign: "left",
    fontFamily: 'Roboto',
    borderLeft: "1px solid black",
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 4,
    paddingTop: 3
  },
  checklistQuantity: {
    width: "5%",
    fontFamily: 'Roboto',
    alignItems: "center",
    display: "flex",
    borderLeft: "1px solid black",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 3
  },
  checklistPassFail: {
    width: "15%",
    textAlign: "center",
    fontFamily: 'Roboto',
    borderLeft: "1px solid black",
    paddingTop: 3
  },
  checklistHours: {
    width: "10%",
    textAlign: "center",
    fontFamily: 'Roboto',
    borderLeft: "1px solid black",
    paddingTop: 3
  },
  checklistCost: {
    width: "10%",
    textAlign: "center",
    fontFamily: 'Roboto',
    borderLeft: "1px solid black",
    paddingTop: 3
  },
  checklistBillClient: {
    width: "15%",
    textAlign: "center",
    fontFamily: 'Roboto',
    borderLeft: "1px solid black",
    paddingTop: 3
  },
  image: {
    height: 300,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: 20
  },
});

