import React, { FC } from "react";
import {
  TextField,
  Autocomplete,
  AutocompleteProps,
  createFilterOptions,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  FilterOptionsState,
  ListItem,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Client } from "store/models/Client";
import { setBladeMode, simpleGlobalMessage } from "store/slices/systemSlice";
import { initClient } from "store/api/client";
import usePermissions, { ActionType, FeatureType } from "hooks/usePermissions";
import { useAppDispatch } from 'store/store';
import { selectDefaultUserCountry } from "store/slices/configSlice";
import { alphabetizeByProperty } from "utils/formatUtils";
import { useGetClientsQuery } from "store/services/client";
import { byIds } from "store/sliceUtils";



type Props = Omit<
  AutocompleteProps<Client, boolean, boolean, boolean>,
  "options" | "renderInput"
> & {
  name?: string;
  error?: boolean;
  "data-testId"?: string;
  label?: string;
  onChange: (
    event: React.SyntheticEvent,
    value: Client,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Client>,
  ) => void
};

const ClientAutocomplete: FC<Props> = ({
  value,
  name,
  error,
  label,
  onChange,
  ...rest
}) => {
  const {data: clients = []} = useGetClientsQuery();
  const dispatch = useAppDispatch();
  const checkContractPermissions = usePermissions(FeatureType.CLIENTS);
  const canCreateClients = checkContractPermissions(ActionType.CREATE);
  const defaultCountry = useSelector(selectDefaultUserCountry)
  const OPTIONS_LIMIT = 100;
  const clientsHash = byIds(clients);

  function handleChange(
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | Client | (string | Client)[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Client> | undefined
  ) {
    newValue = newValue as Client;
    if (newValue && newValue.companyName.includes("Create New Client")) {
      handleClientCreate();
    } else {
      onChange(event, newValue, reason, details);
    }
  }

  function handleClientCreate() {
    if (!canCreateClients) {
      dispatch(simpleGlobalMessage("You do not have the permissions required to create clients"));
    } else {
      dispatch(setBladeMode("clientCreate"));
    }
  }
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (client: Client) => client.companyName,
    limit: OPTIONS_LIMIT,
  });

  function addNewClientOptionIfNoClientMatch(
    options: Client[],
    params: FilterOptionsState<Client>
  ) {
    const filteredOptions = filterOptions(options, params);
    if (params.inputValue && filteredOptions.length === 0) {
      filteredOptions.push(
        initClient({country: defaultCountry, companyName: `Create New Client "${params.inputValue}"` })
      );
    }
    return filteredOptions;
  }

  return (
    <Autocomplete
      {...rest}
      onChange={handleChange}
      value={value || null}
      key={value?.toString()}
      getOptionLabel={(client) => typeof client === "string" ? clientsHash[client]?.companyName : client.companyName}
      renderOption={(props, client) => {
        return <ListItem {...props} sx={{cursor: "pointer"}} key={client._id}>{client.companyName}</ListItem>;
      }}
      filterOptions={addNewClientOptionIfNoClientMatch}
      options={[...clients].sort(alphabetizeByProperty("companyName"))}
      clearOnBlur={false}
      renderInput={(params) => (
        <TextField
          name={name}
          {...params}
          data-testid={rest["data-testId"]}
          error={error}
          margin="none"
          label={label}
          autoComplete="off"
          inputProps={{
            ...params.inputProps,
            autocomplete: "new-password",
            form: { autoComplete: "off" },
          }}
        />
      )}
    />
  );
};

export default ClientAutocomplete;
