import React from 'react';
import { Card, CircularProgress, Typography } from "@mui/material";
import { selectCurrentAssetId } from 'store/slices/assetSlice';
import { useSelector } from 'react-redux';
import { useGetAverageRentalRateByAssetIdQuery } from 'store/services/asset';

interface Props {
}
const AssetAverageRentalRate: React.FC<Props> = () => {
  const currentAssetId = useSelector(selectCurrentAssetId);
  const {data: averageRental, isLoading} = useGetAverageRentalRateByAssetIdQuery(currentAssetId || "", { skip: !currentAssetId });

  return (
    <Card sx={{ px: 3, py: 2, display: "flex", justifyContent: "center" }}>
      <Typography sx={(theme) => ({ marginRight: theme.spacing(2) , color: "text.secondary"})}>{isLoading ? <CircularProgress size={18} /> : averageRental}</Typography>
    </Card>
  );
};
export default AssetAverageRentalRate;
