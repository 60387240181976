import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/store';
import UserData from 'store/models/UserData';
import { Config, updateOneConfig } from 'store/slices/configSlice';
import { setOneLead } from 'store/slices/leadSlice';
import { setOneUser } from 'store/slices/userSlice';
import { io, Socket } from 'socket.io-client';
import { LeadWithPopulatedContact } from 'store/models/Lead';
import { useLocation } from 'react-router';
import { refreshData } from 'utils/util';
import { useSelector } from 'react-redux';
import { selectLoggedInUser } from 'store/slices/systemSlice';

const useSocketController = () => {
  const dispatch = useAppDispatch();
  const loggedInUser = useSelector(selectLoggedInUser)
  const [token, setToken] = useState<string>();

  const location = useLocation()
  const [socketConnection, setSocketConnection] = useState<Socket>();
  
  useEffect(() => {
    async function getToken(){
      const firebaseToken = await getAuth().currentUser?.getIdToken();
      setToken(firebaseToken)
    }
    if(loggedInUser){
      getToken()
    }
  },[loggedInUser])

  useEffect(() => {
    const reconnectInterval = 1000 * 15
    function initSocket(){
      if(!token || !loggedInUser) return;

      const socket = io(`${process.env.REACT_APP_API_URL}`, {transports: ["websocket"], auth: { token, branchIds: loggedInUser.branches, countries: loggedInUser.countries }})
      
      socket.on("connect",() => {
        console.log("Socket Connected")
      })

      socket.on("specificUserUpdate", (user: UserData) => dispatch(setOneUser(user)))
      socket.on("leadUpdate", (lead: LeadWithPopulatedContact) => handleLeadSockets(lead))
      socket.on("updateConfig", (config: Config) => dispatch(updateOneConfig(config)))

      socket.on("disconnect",(reason: Socket.DisconnectReason) => {
        console.log("Socket Disconnected")
        if (["io server disconnect", "io client disconnect"].includes(reason)) {
          socket.connect()
        }
      });
      socket.io.on("reconnect", () => {
        handleReconnect()
      })


      return socket;
    }

     // eslint-disable-next-line no-promise-executor-return
    const reconnectTimer = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    async function handleReconnect() {
      await reconnectTimer(getRandomReconnectInterval(reconnectInterval));
      if (token) {
        refreshData(dispatch);
      }
    }

    function handleLeadSockets(lead: LeadWithPopulatedContact) {
      if (!location.pathname.includes("leads")) return;

      dispatch(setOneLead(lead))

    }

    const socket = initSocket();
    if(socket) {
      socket.open()
      setSocketConnection(socket);
    }

    return () => {
      if (socket) {
        socket?.close()
        setSocketConnection(undefined);
      }
    };
  },[token])

  return socketConnection;

}
export default useSocketController;



function getRandomReconnectInterval(base: number) {
  // some number between 15 seconds and two minutes with base = 15s
  return Math.floor(Math.random() * base * 7) + base;
}