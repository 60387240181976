import React, {
  Dispatch,
  FC,
  SetStateAction,
  useState,
} from "react";
import { Dialog } from "@mui/material";
import { AssetStatus } from "store/models/AssetStatus";
import { titleCase } from "utils/util";
import { LoadingButton } from "@mui/lab";
import { Asset } from "store/models/Asset";
import StyledAssetsTable from "features/assets/table/StyledAssetsTable";
import {
  GridColumnsInitialState,
  GridFilterModel,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import { useGetAllAssetsQuery } from "store/services/asset";

interface ISubmitButton
{
  toggleAllRowsSelected: (set?: boolean | undefined) => void;
  selectedRows: Asset[];
  mode: AddAssetModalMode;
  isSubmitting: boolean;
  handleAssetSubmit: (
    toggleAllRowsSelected: (set?: boolean | undefined) => void,
    selectedRows: Asset[]
  ) => void;
}

const SubmitButton: FC<ISubmitButton> = ({
  handleAssetSubmit,
  selectedRows,
  toggleAllRowsSelected,
  mode,
  isSubmitting,
}) => {
  return (
    <LoadingButton
      disabled={!selectedRows.length}
      loading={isSubmitting}
      color="primary"
      variant="contained"
      onClick={() => {
        handleAssetSubmit(toggleAllRowsSelected, selectedRows);
      }}
    >
      {titleCase(mode)} Asset
    </LoadingButton>
  );
};

export type AddAssetModalMode = "swap" | "add" | "hidden";

export interface AssetTableFilters extends Record<string, string[]> {
  yard: string[];
  sizeCode: string[];
  status: AssetStatus[];
}

interface Props {
  mode: AddAssetModalMode;
  setMode: Dispatch<SetStateAction<AddAssetModalMode>>;
  isSubmitting: boolean;
  filterModel?: GridFilterModel;
  assets?: Asset[];
  enableSingleRowSelection?: boolean;
  handleAssetSubmit: (
    toggleAllRowsSelected: (set?: boolean | undefined) => void,
    selectedRows: Asset[]
  ) => void;
}

const AddAssetModal: FC<Props> = ({
  setMode,
  mode,
  filterModel = {
    items: [{ field: "status", operator: "in-status", value: ["AVAILABLE"] }],
  },
  isSubmitting,
  handleAssetSubmit,
  enableSingleRowSelection = true,
  assets,
}) => {
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);
  const {data: storeAssets = []} = useGetAllAssetsQuery({statuses: ["AVAILABLE"]});
  const assetsHash = storeAssets.reduce((hash, asset) => {
    hash[asset._id] = asset;
    return hash;
  }, {} as Record<string, Asset>);
  
  const tableActions = [
    <SubmitButton
      selectedRows={selectedAssetIds.map((_id) => assetsHash[_id])}
      toggleAllRowsSelected={() => setSelectedAssetIds([])}
      handleAssetSubmit={handleAssetSubmit}
      isSubmitting={isSubmitting}
      mode={mode}
    />,
  ];


  const initialColunmnState: GridColumnsInitialState = {
    orderedFields: [],
    columnVisibilityModel: {
      _id: false,
      assetNumber: true,
      serialNumber: true,
      starfleetHours: true,
      branch: true,
      yard: true,
      status: true,
      yardPosition: true,
      category: true,
      subCategory: true,
      condition: true,
      branding: false,
      manufacturer: false,
      hrs: true,
      notes: false,
      yearOfManufacture: false,
      buildingCode: false,
      acType: false,
      heatingType: false,
      cladding: false,
      electrical: false,
      fuelType: false,
      floorStyle: false,
      sizeCode: true,
      height: false,
      structuralLimit: false,
      frame: false,
      colour: false,
      wash: true,
      partitions: true,
      complex: true,
      axleCount: false,
      windRating: false,
      roofType: false,
      inServiceDate: false,
      orientation: false,
      capitalCost: false,
      accumulatedImpairment: false,
      usefulLife: false,
      residualValue: false,
      depreciationRate: false,
      photos: false,
      version: false,
      attachments: false,
      inProduction: true,
      netBookValue: true,
    },
    dimensions: undefined,
  };




  return (
    <Dialog
      open={mode !== "hidden"}
      onClose={() => setMode("hidden")}
      maxWidth="lg"
      fullWidth
      scroll="body"
    >
      <StyledAssetsTable
        initialState={{ filter: { filterModel }, columns: initialColunmnState }}
        selectRowOnRowClick
        tableActions={tableActions}
        assets={assets}
        tableId="add-asset-table"
        onRowClick={(params: GridRowParams<Asset>) => {
          if (enableSingleRowSelection) {
            setSelectedAssetIds([params.row._id])
          } else {
            setSelectedAssetIds([...selectedAssetIds, params.row._id])
          }
        }
        }
        onRowSelectionModelChange={(selectedAssetIds) => {
          if (enableSingleRowSelection) {
            setSelectedAssetIds(
              [Object.values(selectedAssetIds).map((id) => String(id))[Object.values(selectedAssetIds).length - 1]]
            );
          } else {
            setSelectedAssetIds(
              Object.values(selectedAssetIds).map((id) => String(id))
            );
          }
        }}
        rowSelectionModel={selectedAssetIds as GridRowSelectionModel}
        disableDensitySelector
        disableExport
        persistColumnOrder={false}
        persistPinnedColumns={false}
        persistFilter={false}
        persistSort={false}
      />
    </Dialog>
  );
};

export default AddAssetModal;
