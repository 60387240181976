import { View, StyleSheet, Text } from "@react-pdf/renderer";
import React from "react";
import { IInspection } from "store/models/Inspection";
import { WorkOrder } from "store/models/WorkOrder";
import { AssetWorkOrder, WebWOQueryResponse } from "store/services/workOrder";

interface Props {
  items?: IInspection["inspectionChecklist"];
  workOrder: WebWOQueryResponse | WorkOrder | AssetWorkOrder;
}

const InspectionChecklistItemsTable: React.FC<Props> = ({
  items,
  workOrder,
}) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableHeader} fixed>
        <View style={[styles.tableRow, {borderTop: '1px solid black'}]}>
          <View style={styles.checklistCategory}>
            <Text style={styles.subtitle2}>Category</Text>
          </View>
          <View style={[styles.checklistTask, { paddingLeft: 0 }]}>
            <Text style={styles.subtitle2}>Task</Text>
          </View>
          <View style={styles.checklistQuantity}>
            <Text style={styles.subtitle2}>Qty</Text>
          </View>
          <View style={styles.checklistPassFail}>
            <Text style={styles.subtitle2}>Pass / Fail</Text>
          </View>
          <View style={styles.checklistHours}>
            <Text style={styles.subtitle2}>Hours</Text>
          </View>
          <View style={styles.checklistCost}>
            <Text style={styles.subtitle2}>Cost</Text>
          </View>
          <View style={styles.checklistBillClient}>
            <Text style={styles.subtitle2}>Bill Client</Text>
          </View>
        </View>
      </View>
      <View style={styles.tableBody}>
        {items?.map((item) => (
          <View key={item._id}>
            <View style={styles.tableRow} wrap={false}>
              <View style={styles.checklistCategory}>
                <Text style={[styles.body1, { alignSelf: "center" }]}>
                  {item.category}
                </Text>
              </View>
              <View style={styles.checklistTask}>
                <Text style={styles.body1}>{item.taskName}</Text>
              </View>
              <View style={styles.checklistQuantity}>
                <Text style={styles.body1}>{item.quantity || "-"}</Text>
              </View>
              <View style={styles.checklistPassFail}>
                <Text style={styles.body1}>
                  {workOrder.status === "COMPLETE"
                    ? item.result
                    : "Pass / Fail"}
                </Text>
              </View>
              <View style={styles.checklistHours}>
                <Text style={styles.body1}>{item.estimatedHours}</Text>
              </View>
              <View style={styles.checklistCost}>
                <Text style={styles.body1}>
                  {item.estimatedMaterials.amount}
                </Text>
              </View>
              <View style={styles.checklistBillClient}>
                <Text style={styles.body1}>
                  {workOrder.status !== "COMPLETE"
                    ? "Yes/No"
                    : item.billableToClient
                    ? "Yes"
                    : "No"}
                </Text>
              </View>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default InspectionChecklistItemsTable;

const styles = StyleSheet.create({
  h3: { fontSize: 16, fontWeight: 700 },
  h4: { fontSize: 13, fontWeight: 700 },
  body1: { fontSize: 9 },
  subtitle2: {
    fontSize: 10,
    fontWeight: 600,
    marginHorizontal: 5,
    paddingVertical: 2,
  },
  alignRight: { textAlign: "right" },
  alignCenter: { textAlign: "center" },
  alignLeft: { textAlign: "left" },
  page: {
    padding: "40px 24px 0 24px",
    fontSize: 9,
    lineHeight: 1.6,
    backgroundColor: "#fff",
    fontFamily: "Roboto",
    flex: 1,
    height: "100%",
  },
  footer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    margin: 10,
    padding: 10,
    width: "100%",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  noBorder: { paddingTop: 8, paddingBottom: 0, borderBottomWidth: 0 },
  table: {
    width: "100%",
    fontSize: 9,
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: "#80808080",
  },
  tableBody: {},
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottom: "1px solid black",
    borderLeft: "1px solid black",
    alignItems: "center",
    pageBreakInside: "avoid",
  },
  tableTitle: {
    width: "100%",
    fontSize: 12,
    padding: "6px 12px",
    paddingBottom: 0,
  },
  descriptionColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingVertical: 2,
    width: "50%",
    marginLeft: 4,
  },
  infoCell: {
    flexDirection: "row",
    fontSize: 10,
    alignItems: "center",
    minHeight: 20,
    paddingTop: 3,
    width: "50%",
    fontFamily: "Roboto",
    flexWrap: "wrap",
    border: "0.5px solid black",
  },
  completeColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingVertical: 2,
    borderLeft: "1px solid black",
    width: "15%",
    textAlign: "center",
  },
  completedByColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingVertical: 2,
    borderLeft: "1px solid black",
    width: "20%",
    textAlign: "center",
  },
  completedDateColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingVertical: 2,
    borderLeft: "1px solid black",
    width: "20%",
  },
  underline: {
    borderBottom: "1px solid black",
  },
  checklistCategory: {
    width: "15%",
    fontFamily: "Roboto",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 3,
  },
  checklistTask: {
    width: "35%",
    textAlign: "left",
    fontFamily: "Roboto",
    borderLeft: "1px solid black",
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 4,
    paddingTop: 3,
  },
  checklistQuantity: {
    width: "5%",
    fontFamily: "Roboto",
    alignItems: "center",
    display: "flex",
    borderLeft: "1px solid black",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 3,
  },
  checklistPassFail: {
    width: "15%",
    textAlign: "center",
    fontFamily: "Roboto",
    borderLeft: "1px solid black",
    paddingTop: 3,
  },
  checklistHours: {
    width: "10%",
    textAlign: "center",
    fontFamily: "Roboto",
    borderLeft: "1px solid black",
    paddingTop: 3,
  },
  checklistCost: {
    width: "10%",
    textAlign: "center",
    fontFamily: "Roboto",
    borderLeft: "1px solid black",
    paddingTop: 3,
  },
  checklistBillClient: {
    width: "15%",
    textAlign: "center",
    fontFamily: "Roboto",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    paddingTop: 3,
  },
  image: {
    height: 300,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
  },
});
