import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addDays, getYear, subDays } from "date-fns";
import { activeContractStatuses, Contract, ContractStatus } from 'store/models/Contract';
import { STARFLEET_STORE } from "store/store";
import { Asset } from "store/models/Asset";
import _ from "lodash";
import { toMMDDYYYY } from "utils/util";


export type ContractQueryArgs = {
  startDate: string | null;
  endDate: string | null;
  statuses: ContractStatus[];
};

export type CONTRACTS_STORE = {
  currentContractId: string | undefined;
  currentBillOfLadingId: string | undefined | null;
  currentMovementId: string | undefined;
  billOfLadingMovementId: string | undefined;
  currentRevenueYear: number;
  newReservation: Contract | undefined;
  // ContractId for Movement Blade RTK Query fetch
  movementContractId: string | undefined;
  contractFilters: {
    startDate: string | null;
    endDate: string | null;
    statuses: ContractStatus[];
  };
};

export const initialContractStatusFilters: ContractStatus[] = ["AWAITING APPROVAL", "AWAITING CONFIRMATION", "PENDING DELIVERY"]


export const initialContractDateFilters = { startDate: toMMDDYYYY(subDays(new Date(), 180)), endDate: toMMDDYYYY(addDays(new Date(), 90)) };


export const defaultContractFilterStorageIdentifier = 'defaultContractFilters';
const defaultContractFilters = localStorage.getItem(defaultContractFilterStorageIdentifier);

const initialState: CONTRACTS_STORE = {
  currentContractId: undefined,
  currentBillOfLadingId: undefined,
  currentMovementId: undefined,
  billOfLadingMovementId: undefined,
  currentRevenueYear: getYear(new Date()),
  newReservation: undefined,
  movementContractId: undefined,
  contractFilters: {
    startDate: !defaultContractFilters ? initialContractDateFilters.startDate : JSON.parse(defaultContractFilters).startDate ? JSON.parse(defaultContractFilters).startDate : null,
    endDate: !defaultContractFilters ? initialContractDateFilters.endDate : JSON.parse(defaultContractFilters).endDate ? JSON.parse(defaultContractFilters).endDate : null,
    statuses: defaultContractFilters ? JSON.parse(defaultContractFilters).statuses : initialContractStatusFilters,
  }
};

const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    setCurrentContractId(state, action: PayloadAction<string | undefined>) {
      state.currentContractId = action.payload
    },
    setCurrentBillOfLadingId(state, action: PayloadAction<string | undefined | null>) {
      state.currentBillOfLadingId = action.payload
    },
    setCurrentMovementId(state, action: PayloadAction<string | undefined>) {
      state.currentMovementId = action.payload
    },
    setBillOfLadingMovementId(state, action: PayloadAction<string | undefined>) {
      state.billOfLadingMovementId = action.payload
    },
    setCurrentRevenueYear(state, action: PayloadAction<number>) {
      state.currentRevenueYear = action.payload;
    },
    setNewReservation(state, action: PayloadAction<CONTRACTS_STORE["newReservation"]>) {
      state.newReservation = action.payload;
    },
    setMovementContractId(state, action: PayloadAction<string | undefined>) {
      state.movementContractId = action.payload;
    },
    setContractFilters(state, action: PayloadAction<CONTRACTS_STORE["contractFilters"]>) {
      state.contractFilters = action.payload;
    }
  },
});


export const selectCurrentMovementId = (state: STARFLEET_STORE) => state.contracts.currentMovementId
export const selectCurrentContractId = (state: STARFLEET_STORE) => state.contracts.currentContractId
export const selectMovementContractId = (state: STARFLEET_STORE) => state.contracts.movementContractId
export const selectBillOfLadingMovementId = (state: STARFLEET_STORE) => state.contracts.billOfLadingMovementId
export const selectCurrentBillOfLadingId = (state: STARFLEET_STORE) => state.contracts.currentBillOfLadingId
export const selectNewReservation = (state: STARFLEET_STORE) => state.contracts.newReservation
export const selectContractFilters = (state: STARFLEET_STORE) => state.contracts.contractFilters;

export const contractExistsInStore = (
  contracts: { [_id: string]: Contract },
  contractIdToFind: string
) => {
  return Boolean(contracts[contractIdToFind]);
};

export const selectCurrentRevenueYear = (state: STARFLEET_STORE) => state.contracts.currentRevenueYear;


export function contractIsActive(contract: Contract) {
  return activeContractStatuses.includes(contract.status);
}

export function findRentalRate(assetid: Asset["_id"], contract?: Contract) {
  return (
    contract?.assetDeliverables.find(
      (assetDeliverable) => assetDeliverable.asset === assetid
    )?.rentalRate.amount || 0
  );
}

export const filterValuesAreDefault = (state: STARFLEET_STORE) => {
  const {statuses, startDate, endDate } = state.contracts.contractFilters
  const noStatusChanges = _.isEqual(initialContractStatusFilters, statuses);
  const noDateChanges = _.isEqual(
    { startDate, endDate },
    { startDate: initialContractDateFilters.startDate, endDate: initialContractDateFilters.endDate }
  );


  return noStatusChanges && noDateChanges
}



export const {
  setCurrentContractId,
  setCurrentBillOfLadingId,
  setCurrentMovementId,
  setBillOfLadingMovementId,
  setCurrentRevenueYear,
  setNewReservation,
  setMovementContractId,
  setContractFilters
} = contractSlice.actions;

export default contractSlice.reducer;
