/* eslint-disable import/no-import-module-exports */
import { configureStore, Action, createListenerMiddleware, TypedStartListening, addListener, TypedAddListener } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { ThunkAction } from 'redux-thunk'
import rootReducer from 'store/slices/rootReducer'
import { starfleetApi } from "./services/api";

const appStartMiddleware = createListenerMiddleware();
export type AppStartListening = TypedStartListening<STARFLEET_STORE, AppDispatch>;
export const addAppStartListener = appStartMiddleware.startListening as AppStartListening;
export const addAppListener = addListener as TypedAddListener<STARFLEET_STORE, AppDispatch>;

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    // We have large state so we disable these dev only checks to speed up itteration time, otherwise you have to wait 10 seconds each build
    // https://redux-toolkit.js.org/api/getDefaultMiddleware#customizing-the-included-middleware
    immutableCheck: false,
    serializableCheck: false
  }).prepend(appStartMiddleware.middleware).concat(starfleetApi.middleware)
});

//Hot Module Reloading reducers whenever a reducer code changes
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./slices/rootReducer', () => {
    const newRootReducer = require('./slices/rootReducer').default;
    store.replaceReducer(newRootReducer);
  })
}

// Infer the `RootState` and `AppDispatch` types 
export type STARFLEET_STORE = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, STARFLEET_STORE, unknown, Action<string>>
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store

