import Blade from "components/Blade";
import React, { useEffect, useState } from "react";
import MovementView from "./MovementView";
import { useSelector } from "react-redux";
import { selectBladeMode, setBladeMode, setGlobalMessage, simpleGlobalMessage } from "store/slices/systemSlice";
import {
  selectCurrentMovementId,
  selectMovementContractId,
  setCurrentContractId,
  setCurrentMovementId,
  setMovementContractId,
} from "store/slices/contractSlice";
import { setCurrentAssetId } from "store/slices/assetSlice";
import { Grid, Button, Typography, IconButton, CircularProgress, Box } from "@mui/material";
import usePermissions, {
  ActionType,
  FeatureType,
  UIActionType,
} from "hooks/usePermissions";
import Movement from "store/models/Movement";
import { Form, Formik, FormikHelpers } from "formik";
import Edit from '@mui/icons-material/Edit';
import Save from '@mui/icons-material/Save';
import Close from '@mui/icons-material/Close';
import _ from "lodash";
import MovementForm from "features/movements/blades/MovementForm";
import { useAppDispatch } from 'store/store';
import { MovementValidationSchema } from "utils/formikAPI";
import { useGetMovementByIdQuery, useUpdateNonContractMovementMutation } from "store/services/movement";
import { oneMinute } from "utils/timeValues";
import { dateFromMMDDYYYY, toMMDDYYYY } from "utils/util";
import { useUpdateContractMovementsMutation } from "store/services/contract";

interface Props {
}

const MovementViewEditBlade: React.FC<Props> = () => {
  const bladeMode = useSelector(selectBladeMode);
  const [isEditing, setEditing] = useState(false);
  const currentMovementId = useSelector(selectCurrentMovementId);
  const currentContractId = useSelector(selectMovementContractId)

  const dispatch = useAppDispatch();
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const checkMovementPermissions = usePermissions(FeatureType.MOVEMENT);
  const userCanEditMovements = checkMovementPermissions(ActionType.UPDATE); 
  const checkAssetPermissions = usePermissions(FeatureType.ASSET);
  const [updateContractMovements] = useUpdateContractMovementsMutation()
  const {
    data: movement,
    isLoading,
    isFetching,
    currentData
  } = useGetMovementByIdQuery(
    {
      movementId: currentMovementId || "",
      contractId: currentContractId || "",
    },
    { skip: !currentMovementId, pollingInterval: oneMinute }
  );

    useEffect(() => {
      setEditing(false);
    }, [movement?._id]);

  const [updateNonContractMovement, {reset}] = useUpdateNonContractMovementMutation()

  const viewContract = () => {
    const userCanViewContracts = checkContractPermissions(ActionType.READ, UIActionType.ONCLICK);
    if (!userCanViewContracts) return;

    dispatch(setCurrentContractId(movement?.contract?._id));
    dispatch(setBladeMode("contractViewEdit"));
  };

  const viewAsset = () => {
    const userCanViewAssets = checkAssetPermissions(ActionType.READ, UIActionType.ONCLICK);

    if (movement?.asset && userCanViewAssets) {
      dispatch(setCurrentAssetId(movement?.asset?._id || ""));
      dispatch(setBladeMode("assetViewEdit"));
    }
  };

 const updateMovement = (
    movement: Movement,
    { setSubmitting, resetForm }: FormikHelpers<Movement>
  ) => {
    if (!movement.contract) {
      updateNonContractMovement(movement).unwrap().then((result) => {
          dispatch(
            setGlobalMessage({
              messageText: "Successfully Updated Non Contract Movement",
              severity: "success",
              show: true,
            })
          );
          resetForm({ values: result });
          setEditing(false);
          reset()
      }).catch((error) => {
        reset();
        dispatch(
          setGlobalMessage({
            messageText: `Error updating movement: ${error}`,
            severity: "error",
            show: true,
          })
        );
      })
      .finally(() => setSubmitting(false)) 

    } else if (isContractMovement()) {
      updateContractMovements({contractId: movement.contract, movementUpdates: [movement], action: "Update"}).unwrap()
      .then(({ contract, assets }) => {
        resetForm();
        setEditing(false);
        dispatch(
          setGlobalMessage({
            messageText: "Successfully Updated Contract Movement",
            severity: "success",
            show: true,
          })
        );
      }
      )
      .catch((error) => {
        resetForm();
        setSubmitting(false)
        dispatch(setGlobalMessage({show: true, messageText: `Failed to udpate movement: ${error}`, severity: "error" }))
      }
      );

    }

    function isContractMovement() {
      return movement.contract
    }

  };

  const editMovement = () => userCanEditMovements
      ? setEditing(true)
      : dispatch(
          simpleGlobalMessage(
            "Only a Sales Coordinator or an Administrator can do this"
          )
        );

function fetchingNewMovement() {
  return !currentData && isFetching;
}

const closeBlade = () => {
  dispatch(setBladeMode("none"));
  dispatch(setCurrentMovementId(undefined));
  dispatch(setMovementContractId(undefined));
}

const initialMovementValues = {
  ...movement,
  dateOfMove: toMMDDYYYY(dateFromMMDDYYYY(movement?.dateOfMove || "01-01-2000")),
  asset: movement?.asset?._id,
  accessory: movement?.accessory?._id,
  contract: movement?.contract?._id,
  nonAsset: movement?.nonAsset || null,
} as Movement;

  return (
    <Formik
      initialValues={initialMovementValues}
      enableReinitialize
      validationSchema={MovementValidationSchema}
      onSubmit={updateMovement}
    >
      {({ isSubmitting, values, submitForm }) => {
        return (
          <Form>
            <Blade
              open={Boolean(currentMovementId)}
              changeOpen={closeBlade}
              onTop={bladeMode === "movementViewEdit"}
              actions={
                isEditing
                  ? [
                      <IconButton
                        onClick={submitForm}
                        color="primary"
                        disabled={isSubmitting || _.isEqual(movement, values)}
                        data-testid="submitMovement"
                        key="submitMovement"
                      >
                        {isSubmitting ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Save />
                        )}
                      </IconButton>,
                      <IconButton
                        key="cancel"
                        onClick={() => setEditing(false)}
                      >
                        <Close />
                      </IconButton>,
                    ]
                  : [
                      <IconButton
                        onClick={editMovement}
                        disabled={!userCanEditMovements}
                        color="primary"
                        key="edit"
                      >
                        <Edit />
                      </IconButton>,
                    ]
              }
            >
              {isLoading || fetchingNewMovement() ? (
                <Box
                  sx={{
                    display: "flex",
                    height: "800px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Grid container>
                    <Grid item xs={12}>
                      {movement?.asset ? (
                        <Button onClick={viewAsset} color="inherit">
                          <Typography variant="h5">
                            {movement.asset?.serialNumber} -{" "}
                            {movement.asset?.sizeCode} -{" "}
                            {movement.asset?.category}
                          </Typography>
                        </Button>
                      ) : movement?.accessory ? (
                        <Typography variant="h5">
                          {movement?.accessory?.name}
                        </Typography>
                      ) : (
                        <Typography variant="h5">
                          {movement?.nonAsset?.description}{" "}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Button onClick={viewContract} color="inherit">
                        <Typography variant="body2">
                          {movement?.contract?.customerName || "No Client"}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  {isEditing ? (
                    <MovementForm
                      isForNonContractMovement={!Boolean(movement?.contract)}
                    />
                  ) : (
                    <MovementView />
                  )}
                </>
              )}
            </Blade>
          </Form>
        );
      }}
    </Formik>
  );
};
export default MovementViewEditBlade;
