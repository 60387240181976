import store from "store/store";
import { globalMessageWithAction, setVersionBlocked, logout } from "store/slices/systemSlice";
import { Dispatch, SetStateAction, } from "react";
import { getAuth } from "firebase/auth";
import appVersion from "utils/version";
import { getCurrentUser, getTimezone } from "utils/util";

export const sendToBackend = async <ResponseData, >({
  method = 'GET',
  route = '/',
  body = {},
  file,
  onError = () => {},
  successStatus = 200,
  onData = () => {},
  onSuccess = () => {},
  currentlySubmitting = () => {},
  apiOptions = {disableDefaultErrorMessage: false},
}: {
  method?: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  route?: string;
  body?: any;
  file?: any;
  onError?: (message: string, disableDefaultErrorMessage?: boolean) => void | Dispatch<SetStateAction<string>>;
  successStatus?: number;
  onData?: (
    data: ResponseData
  ) => void | Dispatch<SetStateAction<ResponseData>>;
  currentlySubmitting?: (
    isSubmitting: boolean
  ) => void | Dispatch<SetStateAction<boolean>>;
  onSuccess?: Function;
  apiOptions?: {disableDefaultErrorMessage?: boolean}
}) => {
  const userData = getCurrentUser();
  const userId = userData?._id || '';
  const browserTabId = store.getState().system.browserTabId
  const token = await getAuth().currentUser?.getIdToken();
  const tz = getTimezone();

  try {
    const options: Partial<{method: string; headers: any; body: undefined | string}> = {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        browserTabId,
        userId,
        'App-Version': appVersion,
        tz
      },
      body: undefined,
    };

    const isEmpty = (obj: any) => Object.keys(obj).length === 0 && obj.constructor === Object;

    if (method !== 'GET') {
      if (body) options.body = JSON.stringify(body);
      if (file && !isEmpty(file)) {
        options.body = file;
        // Remove 'Content-Type' header to allow browser to add
        // along with the correct 'boundary'
        delete options.headers['Content-Type'];
      }
    }

    currentlySubmitting(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/${route}`,
      options,
    );

    let fileResponse = false;
    for (var item of response.headers.entries()) {
      if (item[0] === "fileresponse" && item[1] === "true") fileResponse = true;
    }

    if (fileResponse) {
      handleFileResponse(response, "Errors"); 
    } else {
      const { message, body: data } = await response.json();
      if(message.toLowerCase().includes('app version mismatch')){
        store.dispatch(setVersionBlocked(true))
        store.dispatch(logout())
        store.dispatch(globalMessageWithAction(message, "Refresh", () => location.reload(), "warning"))
        return;
      } 
      if (response.status !== successStatus) {
        onError(`${message}`, apiOptions?.disableDefaultErrorMessage);
      } else {
        onData(data);
        onSuccess(message);
      }
    }
    currentlySubmitting(false);
  } catch (error) {
    console.log(error)
    currentlySubmitting(false);
    onError(`${error}`);
  }
};

export const handleFileResponse = async (response: any, fileName: string) => {
  const result = await response.blob();

  const href = window.URL.createObjectURL(result);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
};

export const getQueryStringFromObject = (queryObject: Object) => {
  return Object.entries(queryObject)
    .filter(([key, value]) => Boolean(value))
    .map(
      (param) =>
        `${param[0]}=${
          typeof param[1] !== "string" ? JSON.stringify(param[1]) : param[1]
        }`
    )
    .join("&");
};
 