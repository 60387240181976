import { GridRowId, GridRowModel } from '@mui/x-data-grid-pro';
import React, { useState } from 'react'

interface Props {
    tableId: string
}

const usePinnedRows = ({tableId}: Props) => {
    const pinnedRowsStorageKey = `pinnedRows-${tableId}`;
    const savedPinnedRows = localStorage.getItem(`pinnedRows-${tableId}`);
    const [pinnedRowsIds, setPinnedRowsIds] = useState<{
      top: GridRowId[];
      bottom: GridRowId[];
    }>(savedPinnedRows ? JSON.parse(savedPinnedRows) : {
      top: [],
      bottom: [],
    });
    const getPinnedRowData = React.useCallback((data: (Object & {_id: string})[]) => {
        const rowsData: GridRowModel[] = [];
        const pinnedRowsData: { top: GridRowModel[]; bottom: GridRowModel[] } = {
          top: [],
          bottom: [],
        };
    
        data.forEach((row) => {
          if (pinnedRowsIds.top.includes(row._id)) {
            pinnedRowsData.top.push(row);
          } else if (pinnedRowsIds.bottom.includes(row._id)) {
            pinnedRowsData.bottom.push(row);
          } else {
            rowsData.push(row);
          }
        });
    
        return {
          rows: rowsData,
          pinnedRows: pinnedRowsData,
        };
      }, [pinnedRowsIds]);
  
  
    React.useEffect(() => {
      localStorage.setItem(pinnedRowsStorageKey,JSON.stringify(pinnedRowsIds));
    }, [[pinnedRowsIds]]);

    return {pinnedRowsIds, setPinnedRowsIds, getPinnedRowData}
  
}

export default usePinnedRows