import ViewContainer from 'components/bladeComponents/ViewContainer';
import ViewRow from 'components/bladeComponents/ViewRow';
import React from 'react'
import { ClientContact } from 'store/models/ClientContact';
import { useGetClientByIdQuery } from 'store/services/client';
import { getFullName } from 'utils/util';


interface Props {
    clientContact: ClientContact
}

const ClientContactView: React.FC<Props> = ({clientContact}) => {
    const {data: client} = useGetClientByIdQuery(clientContact.currentClient || '', {skip: !clientContact.currentClient});

  return (
    <ViewContainer title={getFullName(clientContact)} subTitle={client?.companyName}>
      <ViewRow label='Job Title' value={clientContact.companyRole} />
      <ViewRow label="Primary Phone" value={clientContact.primaryPhone} />
      <ViewRow label="Alt Phone" value={clientContact.altPhone} />
      <ViewRow label="Email" value={clientContact.email} />
    </ViewContainer>
  );
}

export default ClientContactView